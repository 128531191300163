import React, { useEffect, useState, useMemo, useCallback } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { Loader } from "../../components/common/loader";
import moment from "moment";
import DatePicker from "react-date-picker";
import calendarIcon from "../../Icons/calendarIcon.svg";
import Currency from "../../utils/Currency.json";
import { InputErrorMessage } from "../../components/errorMessages";
import { useContext } from "react";
import { EditFormContext } from "../../appContext";
import Select from "react-select";
import { useFormik } from "formik";
import { EditProfileLoader } from "../../components/common/loader";
import { brandOptions, getLastElementId } from "../../utils/helperFunctions";
import countryList from "react-select-country-list";
import { FileUploadComponent } from "../../components/loader/loader";
import { XCircleFill } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import Avatar from "../../components/Avatar/Avatar";
import Flatpickr from 'react-flatpickr';
import influencercss from '../influencer/influencer.css';
import {
  editBriefApi,
  getAllUsers,
  getAllInternalMashers,
  fetchIndustryApi,
  createBriefApiV2,
  editBriefApiV2,
  syncLiveblocks,
  getUserApi,
  GEtinstagramapi,
  createInfluencerApi,
  EditInfluencerApi,
} from "../../apis/index";
import {
  getHqUsersFromTypesense,
  getCompaniesFromTypesense,
} from "../../apis/typesenseApis";
import nodata from '../../images/no-data.jpg';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {
  SearchBox,
  InstantSearch,
  InfiniteHits,
  RefinementList,
  SortBy,
  Configure,
  useInstantSearch,
} from "react-instantsearch";
import PhoneInput from "react-phone-number-input";
import { createInfluencerSchema, createInfluencersecSchema } from "../Validation/ValidationSchema";
const options = [
  { value: "Instagram", label: "Instagram" },
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter", label: "Twitter" },
];
export default function Editinfluencer({ closeModal, userId,data }) {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentHandelName, setCurrentHandelName] = useState("");
  const [page, setPage] = useState("page1");
  const [validdata, setvaliddata] = useState(false);
  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ];
  const handleChange_custom = (e) => {
    formik3.handleChange(e);
  };
  
  const formik3 = useFormik({
    initialValues: {
      epercentage: data.ErPercentage || '',
      birthDate: data.DOB || '',
      gender: data.Gender || '',
      companyloc: data.Location || '',
      country: data.Country || '',
    },
    validationSchema: createInfluencersecSchema,
    onSubmit: async (values) => {
       setLoader(true);
      const { epercentage,birthDate,gender,companyloc,country } = formik3.values
      try {
        const payload = {
          "HandleID": data.HandleID,
          "ErPercentage": epercentage,
          "DOB": birthDate,
          "Gender": gender,
          "Location": companyloc,
          "Country": country,

        }

        const res = await EditInfluencerApi(payload);
        setLoader(false);

        if (res.status === 200 || 201) {
          if (res?.data?.status == true) {
            toast.success(res?.data?.message, { id: '001' });
            closeModal();
            location.reload();
          }
          else {
            toast.error(res?.data?.message, { id: '001' });
          }

        } else {

          toast.error(res?.data?.message, { id: '001' });
        }
      } catch (err) {
        toast.error(err, { id: '001' });
      }
     
    },
  });
 
  const handleChangeBirth = (e) => {
    formik3.setFieldValue('birthDate', new Date(e).toISOString());
  };

  return (
    <>
      <div>
               <div>
              <form onSubmit={formik3.handleSubmit}>
                <div className="row">
                  <div>
                    <label className="form-label">
                      Enter ER Percentage
                    </label>
                    <input
                      type="number"
                      className={
                        Boolean(formik3.touched?.epercentage) &&
                          Boolean(formik3.errors?.epercentage)
                          ? "form-control border-danger"
                          : "form-control "
                      }
                      placeholder="ER Percentage"
                      name="epercentage"
                      value={formik3.values.epercentage}
                      onChange={handleChange_custom}
                    />
                    <InputErrorMessage
                      error={formik3.touched.epercentage && formik3.errors.epercentage}
                      marginBottom={5}
                    />
                  </div>
                  <div>
                    <label className="form-label">
                      Date of Birth
                    </label>
                    <Flatpickr
                      className={Boolean(formik3.touched?.birthDate) && Boolean(formik3.errors?.birthDate) ? 'form-control cursor-pointer flatpickr-month border-danger' : 'form-control cursor-pointer flatpickr-month '}
                      style={{ backgroundColor: '#fff' }}
                      options={{ dateFormat: 'M j' }}
                      name='birthDate'
                      value={formik3.values.birthDate}
                      onChange={(event) => {
                        handleChangeBirth(event);
                      }}
                    />
                    <InputErrorMessage
                      error={formik3.touched.birthDate && formik3.errors.birthDate}
                      marginBottom={-5}
                    />
                  </div>

                  <div>
                    <label className='form-label'>
                      Gender
                    </label>
                    <Select
                      className={Boolean(formik3.touched?.gender) && Boolean(formik3.errors?.gender) ? 'select border-select-danger' : 'select '}
                      defaultValue={genderOptions[genderOptions.findIndex(element => element.value === formik3.values.gender)]}
                      options={genderOptions}
                      name="gender"
                      isClearable={false}
                      isSearchable={true}
                      onChange={selectedOption => formik3.setFieldValue('gender', selectedOption.value)}
                      placeholder="Select gender"
                    />
                    <InputErrorMessage error={formik3.touched.gender && formik3.errors.gender} marginBottom={-5} />
                  </div>
                  <div>
                    <label className='form-label'>
                      Location
                    </label>
                    <input
                      type='text'
                      className={Boolean(formik3.touched?.companyloc) && Boolean(formik3.errors?.companyloc) ? 'form-control border-danger' : 'form-control '}
                      placeholder='Enter Location'
                      name='companyloc'
                      value={formik3.values.companyloc}
                      onChange={handleChange_custom}
                    />
                    <InputErrorMessage error={formik3.touched.companyloc && formik3.errors.companyloc} marginBottom={-5} />
                  </div>
                  <div>
                    <label className='form-label'>
                      Country
                    </label>
                    <Select
                      className={Boolean(formik3.touched?.country) && Boolean(formik3.errors?.country) ? 'select border-select-danger' : 'select '}
                      defaultValue={countryOptions[countryOptions.findIndex(element => element.value === formik3.values.country)]}
                      options={countryOptions}
                      name='country'
                      isClearable={false}
                      isSearchable={true}
                      onChange={(value) => {
                        formik3.setFieldValue('country', value.value);
                      }}
                    />
                    <InputErrorMessage error={formik3.touched.country && formik3.errors.country} marginBottom={-5} />
                  </div>

                  <div className="brief-button-container">
                    <button
                      type="button"
                      className="button-close"
                      style={{ width: "100%" }}
                      onClick={() => { closeModal() }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="button-primary"
                      style={{ width: "100%" }}
                    >
                      {loader ? (
                        <div className="text-xs text-center">
                          <EditProfileLoader />
                        </div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
              </div>
    </>

  );
}
