import React, { useContext } from "react";
import ProfileDefault from "../../Assets/img/covers/userDefault.png";
import moment from "moment";
import { Loader } from "../../components/common/loader";
import { DashContext } from "../../appContext";
import { assignBucketUrl } from "../../utils/helperFunctions";
import { Link } from "react-router-dom";
import NotificationListV2 from "./NotificationListV2";

const RecentActivity = ({ currentid, setcurrentid, currentUser }) => {
  const TableData = useContext(DashContext);
  const { dashState } = TableData;
  let _recentActivity = [];
  if (dashState.recentActivity.data) {
    let activities = dashState.recentActivity.data;
    let activityEntity = Object.keys(activities);
    activityEntity?.forEach((element) => {
      activities?.[element]?.forEach((activity) => {
        let url = "";
        let id = "";
        activity.entity = element;
        switch (activity.entity) {
          case "user":
            url = `/mashers/mashers-profile/${activity?.masherProfileId}`;
            id = activity?.masherProfileId;
            break;
          case "brief":
            url = `/briefs/edit-brief/${activity?.attachments?.briefId}`;
            break;
          case "client":
            url = `/brands/brand-profile/${activity?.attachments?.clientId}`;
            break;
          case "briefInvitation":
            url = `/briefs/edit-brief/${activity.attachmentObject?.briefId}`;
            break;
          case "influencer":
            url = `/mashers/mashers-profile/${activity?.attachmentObject?._id}`;
            id = activity?.attachmentObject?._id;
            break;
          case "project":
            url = `/campaigns/campaigns-view/${activity?.attachmentObject?._id}`;
            break;
          default:
            url = "/";
            break;
        }
        activity.redirectUrl = url;
        activity.redirectUrl_id = id;
        activity.redirect_type = element;
        _recentActivity.push(activity);
      });
    });
  }
  return (
    <div className="search-card-table-container">
      {/* <div className='activity-mashup-table-title-block'>Chat Notifications</div> */}
      {/* <NotificationListV2 currentUser={currentUser}/> */}
      <div className="activity-mashup-table-title-block">Recent Activity</div>
      <div className="card">
        {dashState?.recentActivity?.loading ? (
          <div className="text-center p-4 pt-12">
            <Loader />
          </div>
        ) : _recentActivity.length === 0 ? (
          <div className="p-5 text-center ">No Recent Activity</div>
        ) : (
          <div
            className="card-body py-0 position-relative scrollable-y"
            style={{ maxHeight: "89vh" }}
          >
            <div className="list-group list-group-flush">
              {_recentActivity.map((el, i) => {
                return (
                  <div
                    key={`recent-${i}`}
                    className="list-group-item px-0 position-relative hstack flex-wrap"
                  >
                    {el.redirect_type == "user" ||
                    el.redirect_type == "influencer" ? (
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#profilePreview"
                        id="profilePreview_open"
                        onClick={() => {
                          setcurrentid(el.attachmentObject);
                        }}
                        // to={el.redirectUrl}
                        className="d-block stretched-link"
                      >
                        <div className="flex-1">
                          <div className="d-flex align-items-center d-block">
                            <div
                              className="text-sm text-muted me-auto"
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {el?.message}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-1">
                            <span className="text-muted text-xs">
                              {moment(el?.createdAt).fromNow()}
                            </span>
                          </div>
                        </div>
                      </button>
                    ) : (
                      <Link
                        to={el.redirectUrl}
                        className="d-block stretched-link"
                      >
                        <div className="flex-1">
                          <div className="d-flex align-items-center d-block">
                            <div
                              className="text-sm text-muted me-auto"
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {el?.message}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-1">
                            <span className="text-muted text-xs">
                              {moment(el?.createdAt).fromNow()}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentActivity;
