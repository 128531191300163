import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
const QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
import { v4 as uuidv4 } from 'uuid';
import { allClientData, getLastElementId, getClient, style, brandOptions } from '../../utils/helperFunctions.js';
const countries = require('i18n-iso-countries');
import { EditFormContext } from '../../appContext/index.js';
import {
  getUserApi,
  getProjectByIdApiV2,
  getMasherShareApi,
  DeleteProjectAttachApi,
} from '../../apis/index.js';
import NoMashplayerimg from "../../Assets/img/media/no_mashplayer.png";
import DatePicker from 'react-date-picker';
import SimpleModal from '../../components/Modals/SimpleModal.tsx';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
const Typesense = require("typesense");
import PageHeader from '../../components/pageElements/pageHeader/PageHeader.js';
import pdfIcon from '../../Icons/pdfIcon.svg';
import AdminLayout from '../../components/admin-layout/index.js';
import SimpleTextAndTitle from '../../components/text-boxes/SimpleTextAndTitle.js';
import calendarIcon from '../../Icons/calendarIcon.svg';
import closeCrossIcon from '../../Icons/closeCrossIcon.svg';
import plusIconBlack from '../../Icons/plusIconBlack.svg';
import chevronRight from '../../Icons/chevronRight.svg';

import PricingTableProject from './PricingTable/PricingTableProject.js';
import MasherChat from '../../components/MasherChat';
import SimplePrintModal from '../../components/Modals/SimplePrintModal';
import BriefPDFView from '../Briefs/BriefPDFView';
import '../Briefs/briefs.css';
import MasherTeamUpTableProject from './MasherTeamUpTable/MasherTeamUpTableProject.js';
import Emoji from '../../components/emojis/Emojis.jsx';
import { ProjectfileUploadApi, createProjectAttachApi } from '../../apis/index.js';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { FileUploadComponent, FilePreviewComponent } from '../Briefs/EditBrief/components/FileUploadComponent.js';
import DateRange from '../../components/Brief/DateRange.jsx';
import { API_BASE_URL } from '../../utils/baseurl.js';
import { Download } from 'react-bootstrap-icons';
import { AppContext } from '../../appContext/index.js';
import BarChart from '../../components/Graphs/Barchart.js';
import TableListHeader from '../../components/TableList/TableListHeader.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import Moment from 'react-moment';
const ProjectViewV2 = () => {
  const [currentUser, setCurrentUser] = useState('');
  const [signedInUserDetails, setSignedInUserDetails] = useState('');
  const [projectData, setProjectData] = useState();
  const [loading, setLoading] = useState(false);
  const currDate = moment().format('YYYY-MM-DD');
  const navigate = useNavigate();
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [exportPdf, setExportPdf] = useState(false);
  const [pricingTableViewable, setPricingTableViewable] = useState(false);
  const [tsProjectdata, setTsProjectData] = useState();
  const projectId = getLastElementId();

  const getProjectById = async (id) => {
    setLoading(true);
    try {
      const res = await getProjectByIdApiV2(id);
      if (res.status === 200) {
        setProjectData(res.data.project);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };
  const currentUserApi = async () => {
    const res = await getUserApi();
    setCurrentUser(res?.data?.user[0]._id);
    setSignedInUserDetails(res?.data?.user[0]);
  };

  useEffect(() => {
    getProjectById(projectId);
    currentUserApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getFlagEmoji(countryCode) {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    return '';
  }

  const handlePricingTableView = () => {
    setPricingTableViewable(!pricingTableViewable);
  };

  const formik = useFormik({
    initialValues: {
      attachments: '',
    },
  });

  useEffect(() => {
    formik.setValues({
      attachments: projectData?.attachments || null,
    });
  }, [projectData]);
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      // Process each accepted file
      acceptedFiles.forEach((file, index) => {
        fileupload(file, index, acceptedFiles.length);
      });
    }
  }, []);

  const [uploadPercentage, setUploadPercentage] = useState([]);
  const [filename, setfileName] = useState([]);
  const [filesize, setfilesize] = useState([]);
  const [fileiserror, setfileiserror] = useState([]);
  const [isfile, setisfile] = useState(false);
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  const onUploadProgress = (progressEvent, file, index) => {
    setUploadPercentage((prevUploadPercentage) => {
      const updatedProgress = [...prevUploadPercentage];
      updatedProgress[index] = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      return updatedProgress;
    });

    setfileName((prevFileName) => {
      const updatedFileName = [...prevFileName];
      updatedFileName[index] = file.name;
      return updatedFileName;
    });

    setfilesize((prevFileSize) => {
      const updatedFileSize = [...prevFileSize];
      updatedFileSize[index] = formatFileSize(file.size);
      return updatedFileSize;
    });
  };

  const fileupload = async (file, index, totalFiles) => {
    try {
      setisfile(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('projectId', projectId);

      // Make the file upload request
      const uploadResponse = await ProjectfileUploadApi(formData, (progressEvent) => onUploadProgress(progressEvent, file, index));

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // If the file upload was successful, update the filename and filesize
        setfileName((prevFileName) => {
          const updatedFileName = [...prevFileName];
          updatedFileName[index] = file.name;
          return updatedFileName;
        });

        setfilesize((prevFileSize) => {
          const updatedFileSize = [...prevFileSize];
          updatedFileSize[index] = formatFileSize(file.size);
          return updatedFileSize;
        });

        // Create a brief attachment
        const createBriefAttachResponse = await createProjectAttachApi({
          'projectId': projectId || '',
          'fileName': file.name,
          'filePath': uploadResponse.data.filePath || '',
          'fileSize': formatFileSize(file.size)
        });

        if (createBriefAttachResponse.status === 200 || createBriefAttachResponse.status === 201) {
          setfileiserror((prevFileName) => {
            const updatedstatus = [...prevFileName];
            updatedstatus[index] = false;
            return updatedstatus;
          });
          // alert(JSON.stringify(createBriefAttachResponse?.data?.Attachments?.[0]?.attachments,null,2))
          formik.setValues({ attachments: createBriefAttachResponse?.data?.Attachments?.[0]?.attachments });
        } else {
          setfileiserror((prevFileName) => {
            const updatedstatus = [...prevFileName];
            updatedstatus[index] = true;
            return updatedstatus;
          });
        }
      } else {
        // Handle error in file upload
        setfileiserror((prevFileName) => {
          const updatedstatus = [...prevFileName];
          updatedstatus[index] = true;
          return updatedstatus;
        });
      }
    } catch (error) {
      // Handle any other errors
      console.error('An error occurred:', error);
      setfileiserror((prevFileName) => {
        const updatedstatus = [...prevFileName];
        updatedstatus[index] = true;
        return updatedstatus;
      });
    }
    finally {
      // This block will always be executed  
      if (index === totalFiles - 1) {
        setisfile(false);
      }
    }
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });
  console.log(projectData);
  useEffect(() => {
    if (formik.values.attachments && Array.isArray(formik.values.attachments)) {
      // If formik.values.attachments is not null or undefined and is an array
      setFileDeleteLoader(Array(formik.values.attachments.length).fill(false));
    }
  }, [formik.values.attachments]);
  const [filedeleteloader, setFileDeleteLoader] = useState([]);
  const Deletefun = async (id, index) => {
    try {
      setFileDeleteLoader(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      const res = await DeleteProjectAttachApi(projectId, id);

      if (res.status === 200 || 201) {
        toast.success(res.data.message, { id: 'succcess' });
        // alert(JSON.stringify(res.data.result.attachments))
        formik.setValues({ attachments: res.data.result.attachments });
      }
    } catch (error) {
      toast.error(error.response.data.message, { id: '001' });
    }
    finally {
      // This block will always be executed  
      setFileDeleteLoader(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };
  const projectHeaders = [
    {
      title: "Influencer Name",
    },
    {
      title: "Profile Link",
    },
    {
      title: "Total Post",
    },
    {
      title: "Followers",
    },
    {
      title: "Following",
    },
    {
      title: "1-500Acc",
    },
    {
      title: "500-2k",
    },
    {
      title: "2k-All",
    },
    //  {
    //     title: 'Expected Start Date'
    //   },
    //   {
    //     title: 'Expected End Date'
    //   },

    {
      title: 'Last Updated At'
    }
  ];


  const { loggedInUser } = useContext(AppContext);
  const userId = loggedInUser?.data[0]?._id;
 
  function calculateLikePercentage(hits) {
    const likePercentages = [];

    hits.forEach(hit => {
        const likedata = hit.document.likedata;
        let totalLikes = 0;

        // Calculate total likes
        likedata.forEach(data => {
            totalLikes += data.like_count;
        });

        // Calculate average likes
        const avgLikes = likedata.length > 0 ? totalLikes / likedata.length : 0;

        // Calculate percentage (within 100)
        const maxLikeCount = Math.max(...likedata.map(data => data.like_count));
        const percentage = maxLikeCount > 0 ? Math.round((avgLikes / maxLikeCount) * 100) : 0;

        // Push object with name and percent properties
        likePercentages.push({
          Player: hit.document.Name, // Assuming hit has a name property
          Points: percentage
        });
    });

    return likePercentages;
}
  const [bardata, setbardata] = useState(null);
  // GET PROJECTS FROM TYPESENSE
  const TsClientProjects = new Typesense.Client({
    nodes: [
      {
        host: process.env.REACT_APP_HOST_NAME_NEW, // For Typesense Cloud use xxx.a1.typesense.net     // For Typesense Cloud use 443
        protocol: "https", // For Typesense Cloud use https
      },
    ],
    apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
    connectionTimeoutSeconds: 2,
  });
  const searchParametersProjects = {
    q: `${projectId ? projectId : '*'}`,
    query_by: "campaignsName,campaignsId",
  };
  const fetchProjectDataFromTs = async () => {
    const d = await TsClientProjects.collections("evelogs")
      .documents()
      .search(searchParametersProjects)
      .then(function (searchResults) {
        const result = calculateLikePercentage(searchResults.hits);
         const stringifiedResult = JSON.stringify(result);
        setbardata(stringifiedResult)
       
        return searchResults;
      });

    setTsProjectData(d);
  };

  const columnWidthProjects = () => {
    return `${100 / projectHeaders.length}%`;
  };
  const columnWidth = () => {
    return `${100 / projectHeaders.length}%`;
  };
  useEffect(() => {
    if (loggedInUser) {
      fetchProjectDataFromTs();
    }
  }, [loggedInUser]);
  function calculatePercentage500(likedata) {
    let lessThan500 = 0;
    likedata.forEach((post) => {
      const likeCount = post.like_count;
      if (likeCount < 500) {
        lessThan500++;
      }
    });
    return (lessThan500 / likedata.length) * 100;
  }
  function calculatePercentage1k(likedata) {
    let between500And2000 = 0;
    likedata.forEach((post) => {
      const likeCount = post.like_count;
      if (likeCount >= 500 && likeCount <= 2000) {
        between500And2000++;
      }
    });
    return (between500And2000 / likedata.length) * 100;
  }
  function calculatePercentage2k(likedata) {
    
    let above2000 = 0;
    likedata.forEach((post) => {
      const likeCount = post.like_count;
      if (likeCount > 2000) {
        above2000++;
      }
    });
    return (above2000 / likedata.length) * 100;
  }

  return (
    <>
      <AdminLayout>
        <PageHeader title={!loading ? projectData?.name : 'Loading'}
          buttonsArray={[
            <button
              className="simple-button primary"
              role="link"
            // onClick={startBriefDetails}
            >
              <a href={`${API_BASE_URL}/api/v1/common/exports/downloadExcel?userId=${userId}&Id=${projectData?._id}`} className="flex items-center gap-2">
                <Download />
                Report
              </a>

            </button>

          ]}
        />
        {!loading &&
          <>
            <div className='container-fluid brief-container'>
              <div className='brief-details-window'>
                <form>
                  <div className='brief-details' style={{ marginBottom: '75px' }}>
                    <div className='brief-details-row'>
                      <SimpleTextAndTitle title="Brief Owner" bodyText={projectData?.owner ? `${projectData?.owner[0]?.firstName} ${projectData?.owner[0]?.lastName}` : 'Not Selected'} />

                      <SimpleTextAndTitle title="Company Name" bodyText={projectData?.companyName} />

                      <div className='brief-element-wrapper'>

                        <SimpleTextAndTitle title="Company Country" bodyText={projectData?.companyCountry ? projectData.companyCountry[0].countryName : 'Not Selected'} startIcon={<Emoji className='brief-flag' codePoint={getFlagEmoji(projectData?.companyCountry ? projectData.companyCountry[0].countryISO : '')} />} />
                        <SimpleTextAndTitle title="Brief Currency" bodyText={projectData?.companyCurrency || 'Not Selected'} />
                      </div>
                      <div className='brief-industry-container'>
                        <h3 style={{ fontSize: '18px', paddingBottom: '10px' }}>Industry</h3>
                        <div className='brief-industry-list'>
                          {projectData?.industry?.map((i, index) => {
                            return (
                              <div className='brief-badge' key={index}>
                                <p style={{ marginRight: '4px' }}>{i.industryName}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='brief-details-row'>
                      <SimpleTextAndTitle title="HQ Contributers" bodyText={projectData?.contributors ? projectData?.contributors[0].firstName : 'Not Selected'} />
                      <SimpleTextAndTitle title="Expected Start Date" bodyText={moment(projectData?.projectExpectedStartDate).format('YYYY-MM-DD')} startIcon={<img className='calendar-icon' src={calendarIcon} />} />
                      <SimpleTextAndTitle title="Expected End Date" bodyText={moment(projectData?.projectExpectedEndDate).format('YYYY-MM-DD')} startIcon={<img className='calendar-icon' src={calendarIcon} />} />
                    </div>


                  </div>
                  <p className="text-[32px] font-bold text-black mt-12">Deliverables</p>

                  {projectData?.deliverables ?
                    projectData?.deliverables
                      ?.map((el, i) => {
                        return (
                          <div className='brief-element'>
                            <div >
                              <p className="font-bold text-[24px] text-[#101828] mt-8">{el.deliverableName}</p>
                              <div>
                                <DateRange projectId={projectId} deliverable={el} />
                              </div>
                            </div>
                            <div className='brief-text-block'>
                              <p className='brief-textbox text-[#101828] mt-2 mb-5'>
                                {
                                  el.deliverableDescription ?
                                    <div className='converted-html-field' dangerouslySetInnerHTML={{ __html: new QuillDeltaToHtmlConverter(JSON.parse(el.deliverableDescription), {}).convert() }}></div>
                                    : 'NA'
                                }
                              </p>
                            </div>
                          </div>
                        );
                      })
                    :
                    <p>NA</p>
                  }

                 

                  <div style={{ width: '97%' }}>
                    {projectData?.deliverables && <MasherTeamUpTableProject briefData={projectData} />}
                  </div>
                  {
                    bardata &&
                    <div className='m-4'>
                      <h2 className='brief-deliverables-header mt-8'>Audience Reachability</h2>
                      <BarChart data={bardata} width={400} height={600} id="chart" />
                    </div>

                  }
                  <h2 className='brief-deliverables-header mt-8'>Supporting Documents</h2>
                  <div style={{ width: '97%' }}>

                    {!isfile && (
                      <div className='w-full bg-white rounded-xl flex justify-center items-center h-20' {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <div className='flex flex-row items-center text-center'>
                            <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-[24px] text-[#475467]" /></div>
                            <p className='font-semibold text-[#00A46F] ml-6'>Drop here</p>
                          </div>
                        ) : (
                          <div className='flex flex-row items-center text-center'>
                            <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-[24px] text-[#475467]" /></div>
                            <p className='font-semibold text-[#00A46F] ml-6'>Click to upload <span className='text-[#667085] font-normal hidden md:block'>order drag and drop</span></p>
                          </div>
                        )}
                      </div>
                    )}
                    {(uploadPercentage?.length > 0 && isfile) && (
                      <div>
                        {uploadPercentage.map((progress, index) => (
                          <div key={index}>
                            <FileUploadComponent name={filename[index]} filesize={filesize[index]} percentage={progress} isfileerror={fileiserror[index]} />
                          </div>
                        ))}
                      </div>
                    )}



                    {
                      formik.values.attachments && (
                        <div>
                          {formik.values.attachments?.length > 0 &&
                            formik.values.attachments.map((attachment, index) => (
                              <div key={index} className='w-full rounded-xl'>
                                <div>
                                  <FilePreviewComponent
                                    name={attachment.fileName}
                                    filesize={attachment.fileSize}
                                    url={attachment.filePath}
                                    id={attachment._id}
                                    Deletefun={Deletefun}
                                    filedeleteloader={filedeleteloader[index]}
                                    setFileDeleteLoader={setFileDeleteLoader[index]}
                                    index={index}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      )
                    }
                  </div>
                  <div className="dashboard-projects-table" style={{ width: '88vw' }}>
                    <div className="project-mashup-table-title-block">
                      Campaign Log
                    </div>
                    <TableListHeader
                      headers={projectHeaders}
                      columnWidth={columnWidth()}
                    />
                    <div className="col-12 search-card-main-window">
                      <div className="table-list-body">
                        {!tsProjectdata?.hits ? (
                          <EditProfileLoader />
                        ) : tsProjectdata?.hits[0] ? (
                          tsProjectdata?.hits?.map((hit, i) => {
                            return (
                              <>
                                <div className="table-list-body-item" key={i}>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.Name}
                                  </div>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light overflowtbl"
                                  >
                                    <a href={`https://www.instagram.com/${hit.document?.HandleID}`} target='_blank' rel="noreferrer" >
                                      {`https://www.instagram.com/${hit.document?.HandleID}`}</a>

                                  </div>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.posts}
                                  </div>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.Followers}
                                  </div>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.Following}
                                  </div>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.likedata && (
                                      <>
                                        {/* Calculate the percentage using a separate function */}
                                        {calculatePercentage500(hit.document.likedata)}%
                                      </>
                                    )}
                                  </div>

                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.likedata && (
                                      <>
                                        {/* Calculate the percentage using a separate function */}
                                        {calculatePercentage1k(hit.document.likedata)}%
                                      </>
                                    )}
                                  </div>
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    {hit.document?.likedata && (
                                      <>
                                        {/* Calculate the percentage using a separate function */}
                                        {calculatePercentage2k(hit.document.likedata)}%
                                      </>
                                    )}
                                  </div>
                                  {/* <div
                                style={{ width: columnWidthProjects() }}
                                className="table-list-font-light"
                              >
                            <Moment format="DD/MM/YYYY" date={hit.document?.projectExpectedStartDate} />
                              </div>
                              <div
                                style={{ width: columnWidthProjects() }}
                                className="table-list-font-light"
                              >
                            <Moment format="DD/MM/YYYY" date={hit.document?.projectExpectedEndDate} />
                              </div> */}
                                  <div
                                    style={{ width: columnWidthProjects() }}
                                    className="table-list-font-light"
                                  >
                                    <Moment format="DD/MM/YYYY" date={hit.document?.updatedAt} />
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <div className="pl-container">
                             {NoMashplayerimg && <img src={NoMashplayerimg} alt="..." />}  
                            {/* <img src={NoMashplayerimg} alt="..." /> */}
                            <p className="high-pl">
                              No Campaign Log
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        }
      </AdminLayout>
    </>
  );
};

export default ProjectViewV2;
