import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../appContext";
import userIcon from "../../Icons/userIcon.svg";
import mashIcon from "../../Icons/mashLogoSidebarIcon.svg";
import briefIcon from "../../Icons/briefCaseIcon.svg";
import masherIcon from "../../Icons/mashersPeopleIcon.svg";
import projectsIcon from "../../Icons/projectsIcon.svg";
import customersIcon from "../../Icons/customersIcon.svg";
import { GraphUpArrow } from "react-bootstrap-icons";
import cogIcon from "../../Icons/settingsCogIcon.svg";
import editIcon from "../../Icons/editIcon.svg";
import logoutIcon from "../../Icons/logoutIcon.svg";
import infoManual from "../../Icons/infoManual.svg";
import { assignBucketUrl } from "../../utils/helperFunctions";
import ProfileDefault from "../../Assets/img/covers/userDefault.png";
import { PersonLinesFill } from "react-bootstrap-icons";
import "./sidebar.css";
import ClickUpHelpButton from "../ClickUpHelpButton";

export default function Sidebar() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { state, logout } = appContext;
  const { loggedInUser, isLoggedIn } = state;
  const handleLogout = () => {
    logout(navigate);
  };
  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
  const [sideBarOpen , setSideBarOpen]= useState(false);
  function sidebarStatus(){
    if(sideBarOpen){
      setSideBarOpen(false);
    }else if(!sideBarOpen){
      setSideBarOpen(true);
    }
  }
  return (
    <>
      <section className={`sidebar-container ${sideBarOpen?'mobileNavActive':''}`}>
        <div className="sidebar-top-section">
          <ul className="sidebar-ul">
          <Link to="/">
            <li className="sidebar-li">
              
                {/* <img src={assignBucketUrl(mashIcon)} alt='avatar'/> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                  className="bg-white"
                >
                  <path d="M 17 5 C 14.250484 5 12 7.2504839 12 10 L 12 12 L 10 12 C 7.2504839 12 5 14.250484 5 17 L 5 40 C 5 42.749516 7.2504839 45 10 45 L 33 45 C 35.749516 45 38 42.749516 38 40 L 38 38 L 40 38 C 42.749516 38 45 35.749516 45 33 L 45 10 C 45 7.2504839 42.749516 5 40 5 L 17 5 z M 17 7 L 40 7 C 41.668484 7 43 8.3315161 43 10 L 43 33 C 43 34.668484 41.668484 36 40 36 L 38 36 L 36 36 L 17 36 C 15.331516 36 14 34.668484 14 33 L 14 23 C 14 21.883334 14.883334 21 16 21 L 19 21 L 19 27 C 19 29.197334 20.802666 31 23 31 L 29 31 L 31 31 L 34 31 C 36.197334 31 38 29.197334 38 27 L 38 17 C 38 14.250484 35.749516 12 33 12 L 14 12 L 14 10 C 14 8.3315161 15.331516 7 17 7 z M 10 14 L 12 14 L 14 14 L 33 14 C 34.668484 14 36 15.331516 36 17 L 36 27 C 36 28.116666 35.116666 29 34 29 L 31 29 L 31 23 C 31 20.802666 29.197334 19 27 19 L 21 19 L 19 19 L 16 19 C 13.802666 19 12 20.802666 12 23 L 12 33 C 12 35.749516 14.250484 38 17 38 L 36 38 L 36 40 C 36 41.668484 34.668484 43 33 43 L 10 43 C 8.3315161 43 7 41.668484 7 40 L 7 17 C 7 15.331516 8.3315161 14 10 14 z M 21 21 L 27 21 C 28.116666 21 29 21.883334 29 23 L 29 29 L 23 29 C 21.883334 29 21 28.116666 21 27 L 21 21 z"></path>
                </svg> */}
                <span className="text-white text-lg md:text:2xl font-semibold tracking-widest">WLT</span>
              
            </li>
            </Link>
            <Link to="/briefs">
            <li className="sidebar-li">
              
                <img src={briefIcon} alt="briefs button" />
              
            </li>
            </Link>
            <Link to="/campaigns">
            <li className="sidebar-li">
              
                {/* <img src={projectsIcon} alt="projects button" /> */}
                <GraphUpArrow size={25} color="#fff" fontWeight={600} />
             
            </li>
            </Link>
            <Link to="/influencer">
            <li className="sidebar-li">
             
                <img src={masherIcon} alt="mashers button" />
              
            </li>
            </Link>
            <Link to="/brands">
            <li className="sidebar-li">
             
                <img src={customersIcon} alt="customers button" />
              
            </li>
            </Link>
            {
              loggedInUser?.data[0]?.role==="admin" && (
                <Link to="/user">
                <li className="sidebar-li">
                  
                   <PersonLinesFill  size={25} color="#fff" />
                   
                </li>
                </Link>
              )

            }

          </ul>
        </div>

        <div className="sidebar-bottom-section">
          <ul className="sidebar-ul">
            <li className="sidebar-li">
              <div>
                <img
                  alt="user icon"
                  src={userIcon}
                  onClick={() => setProfileDrawerOpen(!profileDrawerOpen)}
                  onError={(event) => {
                    event.target.src = ProfileDefault;
                    event.onerror = null;
                  }}
                />
              </div>
            </li>
            {/* 'Settings' link is for masher settings for now */}
            {/* <li className="sidebar-li">              
                <img src={infoManual} alt="User manual button" />
            </li> */}
            {/* <li className="sidebar-li">
              <ClickUpHelpButton
                url="https://forms.clickup.com/9003215177/f/8ca4aa9-1242/9S2W1EHK23102MTQ6V"
                open={false}
              />
            </li> */}
          </ul>
        </div>

        {/* profile drawer */}
        {profileDrawerOpen && (
          <div className="sidebar-profile-drawer !fixed !bottom-16 !top-auto">
            <ul className="sidebar-profile-drawer-ul">
              <li className="sidebar-profile-drawer-li">
                <div className="sidebar-profile-drawer-icon">
                  <img
                    alt="..."
                    src={assignBucketUrl(loggedInUser?.data?.[0]?.profilePic)}
                    onError={(event) => {
                      event.target.src = ProfileDefault;
                      event.onerror = null;
                    }}
                    id="sidebar-profile-drawer-avatar"
                  />
                </div>
                <p className="sidebar-profile-drawer-title">{`${loggedInUser?.data[0]?.firstName} ${loggedInUser?.data[0]?.lastName}`}</p>
              </li>
              <li className="sidebar-profile-drawer-li">
                <img
                  id="sidebar-profile-drawer-icon"
                  src={editIcon}
                  alt="edit profile button"
                  className="sidebar-profile-drawer-icon"
                />
                <Link
                  to="/edit-profile"
                  className="sidebar-profile-drawer-title "
                >
                  <p id="sidebar-profile-drawer-link">Edit Profile</p>
                </Link>
              </li>
              <li className="sidebar-profile-drawer-li">
                <img
                  id="sidebar-profile-drawer-icon"
                  src={logoutIcon}
                  alt="edit profile button"
                  className="sidebar-profile-drawer-icon"
                />
                <p
                  className="sidebar-profile-drawer-title"
                  id="sidebar-profile-drawer-link"
                  onClick={handleLogout}
                >
                  Sign out
                </p>
              </li>
            </ul>
          </div>
        )}
        {/* open menu for mobile devices */}
        <div className="lg:hidden rounded-full absolute top-2 right-[-50px] bg-[#101828]/50  text-white p-3 w-11 h-11 flex items-center justify-center" onClick={sidebarStatus}>
        {sideBarOpen?<i className="bi bi-chevron-left"></i>:<i className="bi bi-chevron-right"></i>}
        </div>
      </section>
    </>
  );
}
