import { useEffect, useState } from 'react';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, Configure, useInstantSearch } from 'react-instantsearch';
import { fetchClientApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';
import TableListCompanies from '../TableList/TableListCompanies';
import './globalSearch.css';
import TableListHeader from '../TableList/TableListHeader';
import TableListUser from '../TableList/TableListUser';

export default function TypesenseUserListComponent({ searchClient,opendeactivemodal,ActiveDeActiveUser,startuserdetailsupdate, headers, columnWidth, tablePaddingRequired, blockTitle }) {
 
  const [searchWindowClassName, setSearchWindowClassName] = useState('search-box-visible');


  const LoadingIndicator = () => {
    const { status } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? <EditProfileLoader /> : null;
  };

  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
  
    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };

  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div>
        <p>
          No results for <q>{indexUiState.query}</q>.
        </p>
      </div>
    );
  };

  return (
    <div className="col-12 search-window">
      <div className='global-search'>
        <InstantSearch
          indexName='hqusers'
          searchClient={searchClient}
          routing={true}
        >
          <Configure hitsPerPage={8} />
          <main className={`${searchWindowClassName} search-box-description`}>
            <SearchBox placeholder="Search" />
            <span className="search-box-options-text">Search by Name</span>
          </main>
          <NoResultsBoundary fallback={<NoResults />}>
            <div className='search-card-table-container' style={{padding: tablePaddingRequired ? '15px 55px 12px 70px' : ''}}>
              <div className='project-mashup-table-title-block'>{blockTitle}</div>
              <TableListHeader headers={headers} columnWidth={columnWidth}/>
              <div className="align-items-start search-container">
                <LoadingIndicator />
                <div className="col-12 search-card-main-window">

                  <InfiniteHits className='row list-style' hitComponent={hit => 
                     <TableListUser hit={hit.hit} opendeactivemodal={opendeactivemodal} ActiveDeActiveUser={ActiveDeActiveUser} startuserdetailsupdate={startuserdetailsupdate} columnWidth={columnWidth} />} />
                </div>
              </div>
              
            </div>
          </NoResultsBoundary>
        </InstantSearch>
      </div>
    </div>

  );


}