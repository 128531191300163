import React, { useEffect, useState, useMemo,useContext } from 'react';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import AddButton from '../../components/common/buttons/AddButton';
import AdminLayout from '../../components/admin-layout';
import { UserContext } from '../../appContext';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseCompanyListComponent from '../../components/TypesenseList/TypesenseCompanyListComponent';
import SimpleModal from '../../components/Modals/SimpleModal';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../../components/common/loader.js';
import {
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  fetchCountrycodeApi,
  getUserApi,
  updateUsersActiveDeactiveApi
} from "../../apis/index.js";
import { EditFormContext } from '../../appContext';
import TypesenseUserListComponent from '../../components/TypesenseList/TypesenseUserListComponent.js';
import toast from 'react-hot-toast';
import TypesenseEventListComponent from '../../components/TypesenseList/TypesenseEventLogComponent.js';
export default function EventLogListView() {
 
//   const appContext = useContext(AppContext);
//   const { state } = appContext;
//   const { loggedInUser } = state;
//   const [formSelects, setformSelects] = useState({
//     region: "",
//     industry: "",
//     engagementType: "",
//     category: "",
//     client: "",
//     loading: true,
//   });
//   const fetchSelectData = async () => {
//     setformSelects({ loading: true });
//     try {
//       const selectResponse = {
//         regionRes: await fetchRegionApi(),
//         countryRes: await fetchCountrycodeApi(),
//         industryRes: await fetchIndustryApi(),
//         engagementRes: await fetchEngagementTypeApi(),
//         cateRes: await fetchCategoryApi(),
//         clientRes: await fetchClientApi(),
//         currentUser: await getUserApi(),
//       };

//       setformSelects({
//         region: selectResponse?.regionRes.data,
//         country: selectResponse?.countryRes.data.countries,
//         industry: selectResponse?.industryRes.data,
//         engagementType: selectResponse?.engagementRes.data,
//         category: selectResponse?.cateRes.data,
//         client: selectResponse?.clientRes.data,
//         currentUser: selectResponse?.currentUser.data,
//         type:selectResponse?.clientRes?.data,
//         loading: false,
//       });
//     } catch (error) {
//       setformSelects({ loading: false });
//     }
//   };
//   useEffect(() => {
//     fetchSelectData();
//   }, [loggedInUser]);
  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'evelogs',
        query_by: 'campaignsName',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  const tableHeaders = [
    {
      title: 'campaigns Name'
    },
    {
        title: 'Influencer Name'
    },
    {
        title: 'Profile links'
    },
    {
        title: 'Total Post'
    },
    {
        title: 'Following'
    },
    {
        title: 'Followers'
    },
    {
        title: 'Audience Reachability'
    },
    {
        title: 'Expected Start Date'
      },
      {
        title: 'Expected End Date'
      },
      {
        title: 'Last Updated At'
      }
   
   
  ];
 
  const columnWidth = () => {
    return `${100 / tableHeaders.length}%`;
  };


  return (
    <AdminLayout>
      <UserContext.Provider>
      <PageHeader title='Event Log' />
       
        <div className='container-fluid'>
          <article>
            <main className='pt-6 d-flex flex-column'>
              <TypesenseEventListComponent
                searchClient={searchClient}
                headers={tableHeaders}
                columnWidth={columnWidth()}
                blockTitle='evelogs'
                tablePaddingRequired={false}
              /> 
            </main>
          </article>
        </div>          
      </UserContext.Provider>
    </AdminLayout>
  );
}
