import { Link } from 'react-router-dom';
import { useState } from 'react';
import ShareBriefModal from '../Popups/ShareBriefModal';
import { Highlight } from 'react-instantsearch';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import CountryBadge from '../badges/CountryBadge';
import Tag from '../tags/Tag';
import ProfilePreview from '../../Pages/MasherProfileView';
import Moment from 'react-moment';
import InflencerPreview from '../../Pages/InfluencerProfilePreview';
import Bannerimg from '../../Assets/img/cards/banner.png'
import { PenFill, PencilSquare, ThreeDots } from 'react-bootstrap-icons';
import SimpleModal from '../Modals/SimpleModal';
import Createinfluencer from '../../Pages/influencer/Creatinfluencer';
import Editinfluencer from '../../Pages/influencer/Editinfluencer';

export default function MashCard({ hit, currentid, setcurrentid,options}) {
  const [startBrief, setStartBrief] = useState(false);
  const startBriefDetails = () => {
    setStartBrief(!startBrief);
    seteditpopup(false);
  };
  const { skills } = hit;
  const isNewMasher = moment(hit.createdAt) > new Date(new Date().setDate(- 30));
  const [openBriefModal, setOpenBriefModal] = useState(false);
  const handleModal = () => {
    setOpenBriefModal(!openBriefModal);
  };

  function formatInstagramPostNumber(number) {
    if (number < 1000) {
      return number.toString(); // Numbers less than 1000 are displayed as-is
    } else if (number < 1000000) {
      return Math.floor(number / 1000) + 'K'; // Numbers between 1000 and 999,999 are displayed in "k" format
    } else {
      return Math.floor(number / 1000000) + 'M'; // Numbers equal to or greater than 1,000,000 are displayed in "m" format
    }
  }
  const getRoleLabels = (roles) => {
    let roleLabelsSet = new Set();
    if (roles) {
      roles.forEach(role => {
        roleLabelsSet.add(role.label);
      });
    } else {
      roleLabelsSet.add('No roles selected by Masher');
    }
    return Array.from(roleLabelsSet).join(' / ');
  };
  const [editpopup, seteditpopup] = useState(false);

  return (
    <div className='search-card-list-items' >

      <div className="search-card-user-container"  >
        {
          options!=='no' &&  <div className="absolute flex justify-end text-white right-corner cursor-pointer" style={{ zIndex: 999 }}
          onClick={() => { seteditpopup(!editpopup) }}
        >
          <ThreeDots size={20} />

        </div>
        }
       
        {
          editpopup &&
          <div className="absolute flex justify-end right-corner cursor-pointer">
            <div className='popupcontainer shadow-md rounded-sm p-2'>
              <div className='flex items-center justify-start gap-2' onClick={() => { startBriefDetails() }}>
                <div><PencilSquare /></div>
                <div className='text-sm font-medium text-gray-700'>Edit</div>
              </div>
            </div>
          </div>
        }

        <div className='w-full' 
          style={{pointerEvents:(options=="no") ? 'none':'auto'}}
          data-bs-toggle="modal"
          data-bs-target="#influencerPreview"
          id="influencerPreview_open"
          onClick={() => { setcurrentid(hit); seteditpopup(false) }}>
          <div className="search-card-user-header">
            <button
              data-bs-toggle="modal"
              data-bs-target="#influencerPreview"
              id="influencerPreview_open"
              onClick={() => { setcurrentid(hit); }}
            >
              <div className="search-card-image-container">
                <p
                  style={{ backgroundImage: `url(${hit.Photo || hit.Photo || ProfileDefault})`, backgroundSize: 'cover' }}
                  className="search-card-image"
                ></p>
              </div>
            </button>
          </div>
          <div className="search-card-masher-details">
            <div className="search-card-masher-line-one">
              <span>
                <button>
                  {hit.Name}
                </button>
              </span>
            </div>
            <div className="search-card-masher-line-one">
              <span>
                {hit.HandleID}
              </span>
            </div>

            <div className="flex-cotainer-insta">
              <div className="flex-inshits">
                <div className="flex-insight-num">{formatInstagramPostNumber(hit.posts)}</div>
                <div className="flex-insight-2ndtxt">Posts</div>
              </div>
              <div className="flex-inshits">
                <div className="flex-insight-num">{formatInstagramPostNumber(hit.Followers)}</div>
                <div className="flex-insight-2ndtxt">followers</div>
              </div>
              <div className="flex-inshits">
                <div className="flex-insight-num">{formatInstagramPostNumber(hit.Following)}</div>
                <div className="flex-insight-2ndtxt">following</div>
              </div>
            </div>
            <div className="search-card-masher-line-one last-updated mt-2">
              <span>
                Last updated at <Moment format="DD/MM/YYYY" date={hit.updatedAt} />
              </span>
            </div>
          </div>
        </div>

      </div>
      {/* <div  data-bs-toggle="modal"
            data-bs-target="#influencerPreview"
            id="influencerPreview_open"
             onClick={()=>{setcurrentid(hit);}}
              className="dark:!bg-navy-800 shadow-shadow-500 shadow-3xl rounded-primary relative mx-auto flex h-full w-full max-w-[550px] flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-white dark:shadow-none">
      
      <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover" style={{ backgroundImage: `url("${Bannerimg}")` }}>
        <div className="absolute -bottom-12 flex h-[88px] w-[88px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400">
          <img className="h-full w-full rounded-full" src={hit.Photo || hit.Photo || ProfileDefault} alt="" />
        </div>
      </div>
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-bluePrimary text-lg font-bold">{hit.Name}</h4>
        <p className="text-lightSecondary text-base font-normal">{hit.HandleID}</p>
      </div>
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-bluePrimary text-lg font-bold">{formatInstagramPostNumber(hit.posts)}</h3>
          <p className="text-lightSecondary text-sm font-normal">Posts</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-bluePrimary text-lg font-bold">{formatInstagramPostNumber(hit.Followers)}</h3>
          <p className="text-lightSecondary text-sm font-normal">Followers</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-bluePrimary text-lg font-bold">{formatInstagramPostNumber(hit.Following)}</h3>
          <p className="text-lightSecondary text-sm font-normal">Following</p>
        </div>
      </div>
      <div className="search-card-masher-line-one last-updated mt-2">
            <span>
              Last updated at <Moment format="DD/MM/YYYY" date={hit.updatedAt} />
            </span>
          </div>
    </div> */}

      <div className="search-card-right" >
        {openBriefModal && <ShareBriefModal masher={hit} handleModal={handleModal} />}
      </div>
      {startBrief && (
        <SimpleModal
          title="Edit Influencer"
          // description="All fields are mandatory"
          closeModal={startBriefDetails}
        >
          <Editinfluencer closeModal={startBriefDetails} data={hit} />
        </SimpleModal>
      )}
    </div>
  );
}