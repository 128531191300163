import React, { useContext, useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import AdminLayout from "../../components/admin-layout";
import PageHeaderAvatar from "../../components/pageElements/pageHeader/PageHeaderAvatar";
import AddButton from "../../components/common/buttons/AddButton";
import SimpleModal from "../../components/Modals/SimpleModal";
import EditBrief from "../Briefs/EditBrief";
import { UpdateLastLoginApi, getRecentActivityApi, getUserApi } from "../../apis";
import {
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  fetchCountrycodeApi,
} from "../../apis/index.js";
import AllDashboardTables from "./AllDashboardTables";
import ReactTooltip from "react-tooltip";
import { AppContext, DashContext, EditFormContext } from "../../appContext";
import DashtotalCounts from "./DashtotalCounts";
import TableListHeader from "../../components/TableList/TableListHeader.js";
import { EditProfileLoader } from "../../components/common/loader.js";
import Moment from "react-moment";
import Avatar from "../../components/Avatar/Avatar.js";
const Typesense = require("typesense");
import NoMashplayerimg from "../../Assets/img/media/no_mashplayer.png";
import axios from "axios";
import ProfilePreview from "../MasherProfileView.js";
import { allClientData } from "../../utils/helperFunctions.js";

export default function Dashboard({ currentUser }) {
  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;
  const [startBrief, setStartBrief] = useState(false);
  const [currentid, setcurrentid] = useState("");
  const [masherDataShare, setMasherDataShare] = useState({
    data: null,
    loading: false,
  });
  const [tsBriefdata, setTsBriefData] = useState();
  const [tsProjectdata, setTsProjectData] = useState();
  const [dashState, setDashState] = useState({
    dashCount: { data: null, loading: false },
    recentActivity: { data: null, loading: false },
    latestBrief: { data: null, loading: false },
    masherData: { data: null, loading: false },
  });
  const [hqUsers, setHqUsers] = useState();
  const [formSelects, setformSelects] = useState({
    region: "",
    industry: "",
    engagementType: "",
    category: "",
    client: "",
    loading: true,
  });
  const updatelastlogin=async()=>{
   
    if (loggedInUser?.data[0]?._id) {
      try {
        const res = await UpdateLastLoginApi({userId:loggedInUser?.data[0]?._id});
        if (res.status === 200) {
          
        }
        else {
  
        }
      } catch (error) {
   
      }
    }
  }
  
    const hasUpdatedLastLogin = useRef(false);
    useEffect(() => {
      
      if (!hasUpdatedLastLogin.current && loggedInUser?.data) {
        updatelastlogin();
        hasUpdatedLastLogin.current = true;
      }
    }, [loggedInUser?.data]);
  const [client, setClient] = useState({ data: null, loading: true });
  const [loader, setLoader] = useState(false);
  const RecentActivity = async () => {
    setDashState((v) => ({ ...v, recentActivity: { loading: true } }));
    try {
      const res = await getRecentActivityApi();
      if (res.status === 200) {
        setDashState((v) => ({
          ...v,
          recentActivity: { data: res?.data?.recentActivity, loading: false },
        }));
      }
    } catch (error) {
      setDashState((v) => ({ ...v, recentActivity: { loading: false } }));
    }
  };

  const fetchSelectData = async () => {
    setformSelects({ loading: true });
    try {
      const selectResponse = {
        regionRes: await fetchRegionApi(),
        countryRes: await fetchCountrycodeApi(),
        industryRes: await fetchIndustryApi(),
        engagementRes: await fetchEngagementTypeApi(),
        cateRes: await fetchCategoryApi(),
        clientRes: await fetchClientApi(),
        currentUser: await getUserApi(),
      };

      setformSelects({
        region: selectResponse?.regionRes.data,
        country: selectResponse?.countryRes.data.countries,
        industry: selectResponse?.industryRes.data,
        engagementType: selectResponse?.engagementRes.data,
        category: selectResponse?.cateRes.data,
        client: selectResponse?.clientRes.data,
        currentUser: selectResponse?.currentUser.data,
        loading: false,
       
      });
    } catch (error) {
      setformSelects({ loading: false });
    }
  };

  const getHqUsers = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_HOST_NAME}/collections/hqusers/documents/search?q=*&query_by=`,
        {
          headers: {
            "X-TYPESENSE-API-KEY": process.env.REACT_APP_TYPESENSE_API_KEY,
          },
        }
      )
      .then((res) => setHqUsers(res.data))
      .catch((err) => console.error(err));
  };
  // GET BRIEFS FROM TYPESENSE
  const TsClientBriefs = new Typesense.Client({
    nodes: [
      {
        host: process.env.REACT_APP_HOST_NAME_NEW, // For Typesense Cloud use xxx.a1.typesense.net     // For Typesense Cloud use 443
        protocol: "https", // For Typesense Cloud use https
      },
    ],
    apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
    connectionTimeoutSeconds: 2,
  });
  const searchParametersBriefs = {
    q: `'${loggedInUser?.data[0]?._id}'`,
    query_by:"contributors.userId",
    sort_by: "createdAt:desc",
    filter_by: 'status:!=project',
    page: 1,
    per_page: 8
  };
  const fetchBriefDataFromTs = async () => {
    const d = await TsClientBriefs.collections("briefsv2")
      .documents()
      .search(searchParametersBriefs)
      .then(function (searchResults) {
        return searchResults || null;
      });

    setTsBriefData(d);
  };

  // GET PROJECTS FROM TYPESENSE
  const TsClientProjects = new Typesense.Client({
    nodes: [
      {
        host: process.env.REACT_APP_HOST_NAME_NEW, // For Typesense Cloud use xxx.a1.typesense.net     // For Typesense Cloud use 443
        protocol: "https", // For Typesense Cloud use https
      },
    ],
    apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
    connectionTimeoutSeconds: 2,
  });
  const searchParametersProjects = {
     q: `${loggedInUser?.data[0]?._id}`,
     query_by: "contributors.userId",
    sort_by: "createdAt:desc",
  };
  const fetchProjectDataFromTs = async () => {
    const d = await TsClientProjects.collections("campaigns")
      .documents()
      .search(searchParametersProjects)
      .then(function (searchResults) {
        return searchResults;
      });

    setTsProjectData(d);
  };

  const briefHeaders = [
    {
      title: "Name",
    },
    {
      title: "Company",
    },
    {
      title: "Brief Owner",
    },
    {
      title: "Create Date",
    },
  ];
  const projectHeaders = [
    {
      title: "Name",
    },
    {
      title: "Company",
    },
    {
      title: "Campaign Owner",
    },
    {
      title: "Create Date",
    },
  ];

  const columnWidth = () => {
    return `${100 / briefHeaders.length}%`;
  };
  const columnWidthProjects = () => {
    return `${100 / briefHeaders.length}%`;
  };

  useEffect(() => {
    fetchSelectData();
    getHqUsers();
    RecentActivity();
    const searchFilter = { search: "", page: 1, limit: "" };
    allClientData({ ...searchFilter }, setClient);
    if (loggedInUser) {
      fetchBriefDataFromTs();
      fetchProjectDataFromTs();
    }
  }, [loggedInUser]);

  const startBriefDetails = () => {
    setStartBrief(!startBrief);
  };

  const joinName = (owner) => {
    let fullName = `${owner[0].firstName} ${owner[0].lastName}`;
    return fullName;
  };
  const getUserAvatar = (userId) => {
    const avatarUrl = hqUsers?.hits?.find((hqUser) => {
      return hqUser?.document?._id === userId;
    });
    return avatarUrl?.document?.profilePic;
  };
  return (
    <DashContext.Provider
      value={{
        setMasherDataShare,

        dashState,
      }}
    >
      <AdminLayout>
        <ReactTooltip />

        {/* Content */}
        <main>
          {/* Container */}
          <PageHeaderAvatar
            userAvatar={loggedInUser?.data?.[0]?.profilePic}
            title={`Hi ${loggedInUser?.data?.[0]?.firstName || "Admin"}`}
            buttonsArray={[
              <AddButton
                title="Create Brief"
                altText="craete brief"
                buttonType="primary"
                buttonFunction="openModal"
                onClickEvent={startBriefDetails}
              />,
            ]}
            description=" Welcome to your virtual office. A place to keep up to date with briefs, Campaigns, influencer and clients all in one. It’s just like any
              other office, without the awkward elevator silence."
          />
          <div className="container-fluid">
            <DashtotalCounts />
          </div>
          <div className="dashboard-tables-container pb-12">
            <div className="dashboard-left-column">
              <div className="dashboard-briefs-table">
                <div className="project-mashup-table-title-block">
                  Briefs I'm collaborating on
                </div>
                <TableListHeader
                  headers={briefHeaders}
                  columnWidth={columnWidth()}
                />
                <div className="col-12 search-card-main-window">
                  <div className="table-list-body">
                    {!tsBriefdata?.hits ? (
                      <EditProfileLoader />
                    ) : tsBriefdata.hits[0] ? (
                      tsBriefdata?.hits?.map((hit, i) => {
                        return (
                          <>
                            <div className="table-list-body-item" key={i}>
                              <div
                                style={{ width: columnWidth() }}
                                className="table-list-font-light"
                              >
                                {
                                  <Link
                                    to={`/briefs/edit-brief/${hit.document._id}`}
                                  >
                                    {hit.document?.name}{" "}
                                  </Link>
                                }
                              </div>
                              <div
                                style={{ width: columnWidth() }}
                                className="table-list-font-light"
                              >
                                {hit.document.companyName}
                              </div>
                              {/* <div
                                style={{ width: columnWidth() }}
                                className="table-list-font-bold"
                              >
                                {hit.document.companyCurrency}{" "}
                                {hit.document.feeAllocation[0].totalCost}
                              </div> */}
                              <div
                                style={{
                                  width: columnWidth(),
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="table-list-font-bold"
                              >
                                <Avatar
                                  name={joinName(hit.document.owner)}
                                  id={hit.document.owner[0]?.userId}
                                  url={getUserAvatar(
                                    hit.document.owner[0]?.userId
                                  )}
                                />
                                <span style={{ marginLeft: "6px" }}>
                                  {joinName(hit.document.owner)}
                                </span>
                              </div>
                              <div
                                style={{ width: columnWidth() }}
                                className="table-list-font-light"
                              >
                                <Moment
                                  format="DD/MM/YYYY"
                                  date={hit.document?.createdAt}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="pl-container">
                        <img src={NoMashplayerimg} alt="..." />
                        <p className="high-pl">
                          Your Briefs List is emptier than the vacuum of space
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="dashboard-projects-table">
                <div className="project-mashup-table-title-block">
                Campaigns I'm collaborating on
                </div>
                <TableListHeader
                  headers={projectHeaders}
                  columnWidth={columnWidth()}
                />
                <div className="col-12 search-card-main-window">
                  <div className="table-list-body">
                    {!tsProjectdata?.hits ? (
                      <EditProfileLoader />
                    ) : tsProjectdata?.hits[0] ? (
                      tsProjectdata?.hits?.map((hit, i) => {
                        return (
                          <>
                            <div className="table-list-body-item" key={i}>
                              <div
                                style={{ width: columnWidthProjects() }}
                                className="table-list-font-light"
                              >
                                {hit.document?.refBriefId ? (
                                  <Link
                                    to={`/campaigns/campaigns-view/${hit.document._id}`}
                                  >
                                    {hit.document?.name}{" "}
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/briefs/edit-brief/${hit.document._id}`}
                                  >
                                    {hit.document?.name}{" "}
                                  </Link>
                                )}
                              </div>
                              <div
                                style={{ width: columnWidthProjects() }}
                                className="table-list-font-light"
                              >
                                {hit.document.companyName}
                              </div>
                              {/* <div
                                style={{ width: columnWidthProjects() }}
                                className="table-list-font-bold"
                              >
                                {hit.document.companyCurrency}{" "}
                                {hit.document.feeAllocation[0].totalCost}
                              </div> */}
                              <div
                                style={{
                                  width: columnWidthProjects(),
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="table-list-font-bold"
                              >
                                <Avatar
                                  name={joinName(hit.document.owner)}
                                  id={hit.document.owner[0]?.userId}
                                  url={getUserAvatar(
                                    hit.document.owner[0]?.userId
                                  )}
                                />
                                <span style={{ marginLeft: "6px" }}>
                                  {joinName(hit.document.owner)}
                                </span>
                              </div>
                              <div
                                style={{ width: columnWidthProjects() }}
                                className="table-list-font-light"
                              >
                                <Moment
                                  format="DD/MM/YYYY"
                                  date={hit.document?.createdDate}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="pl-container">
                        <img src={NoMashplayerimg} alt="..." />
                        <p className="high-pl">
                          Your Campaigns List is emptier than the vacuum of space
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-right-column">
              <AllDashboardTables
                currentid={currentid}
                setcurrentid={setcurrentid}
              />
            </div>
          </div>
        </main>
        <EditFormContext.Provider
          value={{
            formSelects,
            client,
            allClientData,
            loader,
          }}
        >
          {startBrief && (
            <SimpleModal
              title="Brief Start"
              description="All fields are mandatory"
              closeModal={startBriefDetails}
            >
              <EditBrief
                briefDetails={null}
                closeModal={startBriefDetails}
                currentUser={currentUser}
              />
            </SimpleModal>
          )}
        </EditFormContext.Provider>
      </AdminLayout>
      <ProfilePreview data={currentid} />
    </DashContext.Provider>
  );
}
