import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { loginApi } from "../../apis/index.js";
import { SignInValidation } from "../../components/Validation/index.js";
import LogoImage from "../../Assets/img/logos/mash_yellow.png";
import Cover from "../../Assets/img/covers/Login.jpg";
import { assignBucketUrl } from "../../utils/helperFunctions.js";
import ProfileDefault from "../../Assets/img/covers/userDefault.png";
import { GoogleLogin } from "@react-oauth/google";
import { verifyToken } from "../../apis/index.js";
import { AppContext } from "../../appContext/index.js";
import { InputErrorMessage } from "../../components/errorMessages/index.js";
import { EditProfileLoader } from "../../components/common/loader.js";

export default function GoogleSignIn() {
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const onVerifyToken = async (values) => {
    try {
      setLoader(true);
      const response = await verifyToken(values);
      const data = response.data;
      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        setIsLoggedIn(true);
        navigate(location?.state?.from || "/");
        toast.success(data.message);
      } else {
        setIsWrongOtp(true);
        setErrorMsg("Wrong OTP");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message, { id: "001" });
    }
  };
  const responseMessage = (response) => {
    const values = { token: response.credential };
    onVerifyToken(values);
  };
  const errorMessage = (error) => {
    console.log();
  };
  const formik = useFormik({
    initialValues: { email: "", keepLoggedIn: false },
    validationSchema: SignInValidation,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setLoader(true);
        const res = await loginApi({ email });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate("/otp", {
            state: {
              email: formik.values.email,
              keepLoggedIn: formik.values.keepLoggedIn,
            },
          });
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: "error" });
      }
    },
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  return (
    <>
      <div
        className={`min-h-screen  flex flex-col justify-center items-center sign-in-up !h-screen w-full  sign-in-bg`}
      >
        <div className="col-lg-3 p-6 overflow-y-auto bg-white sm:h-fit flex flex-col justify-center md:justify-start rounded-xl relative z-20">
          {/* <img src={LogoImage} width="150" /> */}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 50 50"
              className="mx-auto"
            >
              <path d="M 17 5 C 14.250484 5 12 7.2504839 12 10 L 12 12 L 10 12 C 7.2504839 12 5 14.250484 5 17 L 5 40 C 5 42.749516 7.2504839 45 10 45 L 33 45 C 35.749516 45 38 42.749516 38 40 L 38 38 L 40 38 C 42.749516 38 45 35.749516 45 33 L 45 10 C 45 7.2504839 42.749516 5 40 5 L 17 5 z M 17 7 L 40 7 C 41.668484 7 43 8.3315161 43 10 L 43 33 C 43 34.668484 41.668484 36 40 36 L 38 36 L 36 36 L 17 36 C 15.331516 36 14 34.668484 14 33 L 14 23 C 14 21.883334 14.883334 21 16 21 L 19 21 L 19 27 C 19 29.197334 20.802666 31 23 31 L 29 31 L 31 31 L 34 31 C 36.197334 31 38 29.197334 38 27 L 38 17 C 38 14.250484 35.749516 12 33 12 L 14 12 L 14 10 C 14 8.3315161 15.331516 7 17 7 z M 10 14 L 12 14 L 14 14 L 33 14 C 34.668484 14 36 15.331516 36 17 L 36 27 C 36 28.116666 35.116666 29 34 29 L 31 29 L 31 23 C 31 20.802666 29.197334 19 27 19 L 21 19 L 19 19 L 16 19 C 13.802666 19 12 20.802666 12 23 L 12 33 C 12 35.749516 14.250484 38 17 38 L 36 38 L 36 40 C 36 41.668484 34.668484 43 33 43 L 10 43 C 8.3315161 43 7 41.668484 7 40 L 7 17 C 7 15.331516 8.3315161 14 10 14 z M 21 21 L 27 21 C 28.116666 21 29 21.883334 29 23 L 29 29 L 23 29 C 21.883334 29 21 28.116666 21 27 L 21 21 z"></path>
            </svg>
          </div>
          <div className="mt-5">
            <h1 className="ls-tight font-bolder display-5 text-dark mb-5">
              Welcome Back{" "}
            </h1>
            <p className="text-dark h4">We couldn't be happier to have you. </p>
            <div className="col-lg-12 px-0 mt-5">
              <form onSubmit={formik.handleSubmit} className="sign-in-up">
                <h2 className="font-bolder h-4 text-dark mb-8">Login</h2>
                <div className="mb-3">
                  <label className="form-label" htmlFor="email">
                    Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    className={
                      Boolean(formik.touched.email) &&
                      Boolean(formik.errors.email)
                        ? "form-control border-danger transparent-input-border"
                        : "form-control transparent-input-border"
                    }
                    id="email"
                    placeholder="Your email address"
                    value={formik.values.email}
                    onChange={handleChange}
                  />
                </div>
                <InputErrorMessage
                  error={formik.touched.email && formik.errors.email}
                />
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="keepLoggedIn"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "keepLoggedIn",
                          e.target.checked ? true : false
                        )
                      }
                      value={formik.values.keepLoggedIn}
                      id="check_example"
                    />
                    <label className="form-check-label " htmlFor="check_example">
                      Keep me logged in
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn_primary_black w-full"
                    type="submit"
                  >
                    {!loader ? (
                      "Send Verification Code"
                    ) : (
                      <div className="text-xs"><EditProfileLoader/></div>
                    )}
                  </button>
                </div>
              </form>

              <div className="text-center text-lg-start d-flex justify-content-center mt-5">
                <div className="mx-0 text-center text-lg-start">
                  {/* <a
                    className="text-dark text-sm font-semibold si-hover-2 text-center"
                  >
                     Get in touch to find out more.
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
