import React, { Component } from 'react';
import * as d3 from 'd3';

class BarChart extends Component {
    componentDidMount() {
        this.drawChart();
    }
   
//     drawChart() {
//         //const data = [{ "Player": "Mary Jane", "Points": 21 }, { "Player": "Debasis Das", "Points": 14 }, { "Player": "Nishant", "Points": 37 }, { "Player": "Mark", "Points": 15 }, { "Player": "Andrew", "Points": 18 }, { "Player": "Simon", "Points": 34 }, { "Player": "Lisa", "Points": 30 }, { "Player": "Marga", "Points": 20 }];
//         //const data = [{"Player":"k","Points":21},{"Player":"k","Points":28},{"Player":"k","Points":58},{"Player":"k","Points":43},{"Player":"k","Points":41}];
//          const data = JSON.parse(this.props.data) || null;
//         const width = this.props.width || 700;
//         const height = this.props.height || 500;
//         //alert(JSON.stringify(data))
//         const margin = { top: 20, right: 30, bottom: 70, left: 40 }; // Increase the bottom margin
//         const innerWidth = width - margin.left - margin.right;
//         const innerHeight = height - margin.top - margin.bottom;
    
//         const svg = d3.select("#" + this.props.id)
//             .append("svg")
//             .attr("width", width)
//             .attr("height", height)
//             .append("g")
//             .attr("transform", `translate(${margin.left},${margin.top})`);
    
//         const xScale = d3.scaleBand()
//             .domain(data.map(d => d.Player))
//             .range([0, innerWidth])
//             .padding(0.1);
    
//         const yScale = d3.scaleLinear()
//             .domain([0, d3.max(data, d => d.Points)])
//             .range([innerHeight, 0]);
    
//        // Add x-axis labels
// svg.append("g")
//     .attr("transform", `translate(0, ${innerHeight})`)
//     .call(d3.axisBottom(xScale))
//     .selectAll("text")
//     .style("text-anchor", "end")
//     .attr("dx", "-.8em")
//     .attr("dy", ".15em") // Adjust this value to control the vertical position of the label
//     .attr("transform", "rotate(-45)")
//     .style("font-size", "10px") // Adjust the font size as needed
//     .style("font-weight", "bold");
    
//         // Add y-axis
//         svg.append("g")
//             .call(d3.axisLeft(yScale))
//             .selectAll("text")
//             .style("font-size", "12px")
//             .style("font-weight", "bold");
    
//         // Add bars
//         svg.selectAll(".bar")
//             .data(data)
//             .enter()
//             .append("rect")
//             .attr("class", "bar")
//             .attr("x", (d) => xScale(d.Player))
//             .attr("y", (d) => yScale(d.Points))
//             .attr("width", xScale.bandwidth())
//             .attr("height", (d) => innerHeight - yScale(d.Points))
//             .attr("fill", "#86B6F6");
    
//         // Add numbers on top of bars
//         svg.selectAll(".label")
//             .data(data)
//             .enter()
//             .append("text")
//             .attr("class", "label")
//             .text((d) => `${d.Points}`)
//             .attr("x", (d) => xScale(d.Player) + xScale.bandwidth() / 2)
//             .attr("y", (d) => yScale(d.Points) - 5)
//             .attr("font-family", "sans-serif")
//             .attr("font-size", "12px")
//             .attr("fill", "black")
//             .attr("text-anchor", "middle");
//     }
    
drawChart() {
    const data = JSON.parse(this.props.data) || null;
    const width = this.props.width || 700;
    const height = this.props.height || 500;
    const margin = { top: 20, right: 30, bottom: 100, left: 40 }; // Increase the bottom margin for rotated labels
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3.select("#" + this.props.id)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleBand()
        .domain(data.map(d => d.Player))
        .range([0, innerWidth])
        .padding(0.1);

    const yScale = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.Points)])
        .range([innerHeight, 0]);

    // Add x-axis labels with rotation
    svg.append("g")
        .attr("transform", `translate(0, ${innerHeight})`)
        .call(d3.axisBottom(xScale))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")
        .style("font-size", "10px")
        .style("font-weight", "bold");

    // Add y-axis
    svg.append("g")
        .call(d3.axisLeft(yScale))
        .selectAll("text")
        .style("font-size", "12px")
        .style("font-weight", "bold");

    // Add bars
    svg.selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => xScale(d.Player))
        .attr("y", (d) => yScale(d.Points))
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => innerHeight - yScale(d.Points))
        .attr("fill", "#86B6F6");

    // Add numbers on top of bars
    svg.selectAll(".label")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "label")
        .text((d) => `${d.Points}%`)
        .attr("x", (d) => xScale(d.Player) + xScale.bandwidth() / 2)
        .attr("y", (d) => yScale(d.Points) - 5)
        .attr("font-family", "sans-serif")
        .attr("font-size", "12px")
        .attr("fill", "black")
        .attr("text-anchor", "middle");
}

    
    
    
    

    render() {
        return <div id={this.props.id} className='d-flex items-center justify-left w-full'></div>;
    }
}

export default BarChart;
