import React, { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
import * as yup from 'yup';
import { Loader } from '../../components/common/loader';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import calendarIcon from '../../Icons/calendarIcon.svg';
import Currency from '../../utils/Currency.json';
import industry from '../../utils/industry.json';
import { InputErrorMessage } from '../../components/errorMessages';
import { useContext } from 'react';
import { EditFormContext } from '../../appContext';
import Select from 'react-select';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader';
import { brandOptions, getLastElementId } from '../../utils/helperFunctions';
import countryList from 'react-select-country-list';
import { editBriefApi, getAllUsers, getAllInternalMashers, fetchIndustryApi, createBriefApiV2, editBriefApiV2, syncLiveblocks, getUserApi } from '../../apis/index';
import { getHqUsersFromTypesense, getCompaniesFromTypesense } from '../../apis/typesenseApis';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, Configure, useInstantSearch } from 'react-instantsearch';
import axios from 'axios';
import { BriefModalSchema } from '../Validation/ValidationSchema';
export default function EditBrief({ briefDetails, closeModal, currentUser }) {
  const EditContext = useContext(EditFormContext);
  const { getClient, client, loader, formSelects, } = EditContext;
  const [allUsers, setAllUsers] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState();
  const [showCompanyList, setShowCompanyList] = useState(false);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'companies',
          sort_by: 'companyName:asc',
          query_by: 'companyName, companyCountry, industry',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();
  //alert(JSON.stringify(client))
  const briefId = getLastElementId();
  const options = () => {
    return client ? brandOptions(client) : false;
  };
  useEffect(() => {
    async function getOptionsFromApi() {
      const res = await getHqUsersFromTypesense();
      const hqUsers = res.data.hits.map((h) => {
        return h.document;
      });
      if (res.data) {
        const options = res.data.hits.map(data => ({
          value: data.document._id,
          label: data.document.firstName+' '+data.document.lastName
        }));
        setAllUsers(options);
        setUpdating(false);
      }
    }
    getOptionsFromApi().catch((e) => {
      console.log('getOptionsFromApi error: ', e);
    });
  }, []);
  useEffect(() => {
    getcompanies();
  }, []);

  const handleChange = (e) => {
    formik.handleChange(e);
  };
  // const BriefModalSchema = yup.object({
  //   companyId: yup.string().required(),
  //   companyName: yup.string().required(),
  //   briefName: yup.string().required(),
  //   briefOwner: yup.string().required(),
  //   briefExpectedStartDate: yup.string().required(),
  //   briefProposalDueDate: yup.string().required(),
  //   industry: yup.string().required(),
  //   country: yup.string().required(),
  //   currency: yup.string().required()
  // });

  const toggleCompanyList = () => {
    setShowCompanyList(!showCompanyList);
  };
  const getBriefOwnerVal = () => {

    if (briefDetails?.owner) return briefDetails?.owner[0]?.userId;
    if (formSelects?.currentUser) return formSelects?.currentUser?.user[0]?._id;
    if (currentUser?._id) return currentUser?._id;
    return null;
  };
  const [companies, setcompanies] = useState(null);
  const getcompanies = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_HOST_NAME}/collections/companies/documents/search?q=*&query_by=`,
        {
          headers: {
            "X-TYPESENSE-API-KEY": process.env.REACT_APP_TYPESENSE_API_KEY,
          },
        }
      )
      .then((res) => {

        const options = res.data.hits.map(company => ({
          value: company.document._id,
          label: company.document.companyName
        }));
        //alert(JSON.stringify(options));
        setcompanies(options);
      }
      )
      .catch((err) => console.error(err));
  };

  const formik = useFormik({
    initialValues: {
      companyId: briefDetails?.companyId,
      companyName: briefDetails?.companyName,
      briefName: briefDetails?.name,
      briefOwner:briefDetails?.owner[0]?.userId,
      briefExpectedStartDate: briefDetails?.briefExpectedStartDate ? briefDetails.briefExpectedStartDate : new Date(moment().format('YYYY-MM-DD')),
      briefProposalDueDate: briefDetails?.briefProposalDueDate ? briefDetails.briefProposalDueDate : new Date(moment().format('YYYY-MM-DD')),
      industry: briefDetails?.industry ? briefDetails?.industry[0].industryName : '',
      country: briefDetails?.companyCountry[0].countryISO,
      currency: briefDetails?.companyCurrency
    },
    validationSchema:BriefModalSchema,
    onSubmit: async (values) => {
      // const filtered = options().filter((el) => el.value === values.companyName);
      const { currency } = values;

      try {
        setUpdating(true);
        const payload = {
          companyId: values.companyId,
          companyName: values.companyName,
          name: values.briefName,
          briefOwner: values.briefOwner,
          briefExpectedStartDate: values.briefExpectedStartDate,
          briefProposalDueDate: values.briefProposalDueDate,
          industry: values.industry,
          companyCountry: {
            id: values.country ? countries.alpha2ToNumeric(values.country) : '',
            countryISO: values.country || '',
            countryName: values.country ? countries.getName(values.country, 'en', { select: 'all' })[0] : ''
          },
          currency: values.currency
        };

        let res;
        if (briefDetails) {
          payload.id = briefId;
          // await syncLiveblocks({
          //   briefId,
          // });
          res = await editBriefApiV2(payload);
          setUpdating(false);
        } else {
          res = await createBriefApiV2(payload);
        }

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });

          if (briefDetails) {
            closeModal();
          } else {
            window.location.href = `/briefs/edit-brief/${res?.data?.brief?._id}`;
          }
        } else {

        }
      } catch (err) {
        console.log('Brief Modal Error', err);
      }
    },
  });
  //alert(JSON.stringify(Currency))
  const currencyOption = Currency.map(el =>
    ({ value: el.currency.code, label: el.currency.name }));
    const industryOption = industry.map(el =>
      ({ value: el.name, label: el.name }));
      const defaultindustry = industryOption?.find(option => option.value === formik.values.industry);
  const defaultcomapany = companies?.find(option => option.value === formik.values.companyId);
  const defaulthquser = allUsers?.find(option => option.value === formik.values.briefOwner);
//alert(defaulthquser)
  return (
    <>
      {formSelects.loading ? (
        <div className='text-xs text-center'>
          <Loader />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <>
              <div>
                <label className='form-label'>
                  Name of brief
                </label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.briefName) && Boolean(formik.errors?.briefName) ? 'form-control border-danger' : 'form-control '}
                  placeholder='Enter Brief Name'
                  name='briefName'
                  value={formik.values.briefName}
                  onChange={handleChange}
                />
                <InputErrorMessage error={formik.touched.briefName && formik.errors.briefName} marginBottom={-5} />
              </div>
              <div className=''>
                <label className='form-label'>
                  Company
                </label>
                {
                  companies ?
                  <Select
                    // classNamePrefix="select"
                    className={Boolean(formik.touched?.companyName) && Boolean(formik.errors?.companyName) ? 'select border-select-danger ' : 'select '}
                    defaultValue={defaultcomapany}
                    options={companies}
                    name='companyId'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('companyId', value.value);
                      formik.setFieldValue('companyName', value.label);
                    }}
                  />
                  
                  :
                  <div className='text-center p-2 border-dashed border-2 '>
                    No Company data availalbe
                  </div>
                 
                }

                <InputErrorMessage error={formik.touched.companyName && formik.errors.companyName} marginBottom={-5} />
                <div className=''>
                  <label className='form-label'>
                    Country
                  </label>
                  <Select
                    className={Boolean(formik.touched?.country) && Boolean(formik.errors?.country) ? 'select border-select-danger ' : 'select '}
                    defaultValue={countryOptions[countryOptions.findIndex(element => element.value === formik.values.country)]}
                    options={countryOptions}
                    name='country'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('country', value.value);
                    }}
                  />
                  <InputErrorMessage error={formik.touched.country && formik.errors.country} marginBottom={-5} />
                </div>
                <div className=''>
                  <label className='form-label'>
                    Currency
                  </label>
                  <Select
                    className={Boolean(formik.touched?.currency) && Boolean(formik.errors?.currency) ? 'select border-select-danger ' : 'select '}
                    defaultValue={currencyOption.find(option => option.value === formik.values.currency)}
                    options={currencyOption}
                    name='currency'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('currency', value.value);
                    }}
                  />
                  <InputErrorMessage error={formik.touched.currency && formik.errors.currency} marginBottom={-5} />
                </div>
                <div className=''>
                  <label className='form-label'>
                    Brief Owner
                  </label>
                  {
                  allUsers  ?
                  <Select
                  className={Boolean(formik.touched?.briefOwner) && Boolean(formik.errors?.briefOwner) ? 'select border-select-danger ' : 'select '}
                    defaultValue={defaulthquser}
                    options={allUsers}
                    name='briefOwner'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('briefOwner', value.value);
                    }}
                  />
                 
                  :
                  <div className='text-center p-2 border-dashed border-2 '>
                    No Brief Owner availalbe
                  </div>
                 
                }
                 
                  {/* <select
                    className='form-select'
                    name='briefOwner'
                    value={formik.values.briefOwner}
                    onChange={handleChange}>
                    <option hidden>Brief Owner</option>
                    {allUsers.length > 0 &&
                      allUsers?.map((el, i) => {
                        return (
                          <option value={el._id} key={i}>
                            {el.firstName} {el.lastName}
                          </option>
                        );
                      })}
                  </select> */}
                  <InputErrorMessage error={formik.touched.briefOwner && formik.errors.briefOwner} marginBottom={-5} />
                </div>
                <div className='edit-brief-form-modal-date-group'>
                  <div className='edit-brief-form-modal-field'>
                    <label className='form-label'>
                      Expected Start Date
                    </label>
                    <DatePicker
                      name='briefExpectedStartDate'
                      minDate={new Date(moment().format('YYYY-MM-DD'))}
                      clearIcon={null}
                      className={Boolean(formik.touched?.briefExpectedStartDate) && Boolean(formik.errors?.briefExpectedStartDate) ? 'form-control  startFilter  border-danger ' : 'form-control  startFilter '}
                      value={new Date(formik.values.briefExpectedStartDate)}
                      calendarIcon={<img className='calendar-icon' src={calendarIcon} />}
                      onChange={(value) => {
                        formik.setFieldValue('briefExpectedStartDate', value);
                      }}
                    />
                    <InputErrorMessage error={formik.touched.briefExpectedStartDate && formik.errors.briefExpectedStartDate} marginBottom={-5} />
                  </div>
                  <div className='edit-brief-form-modal-field'>
                    <label className='form-label'>
                      Proposal Due Date
                    </label>
                    <DatePicker
                      name='briefProposalDueDate'
                      minDate={new Date(moment().format('YYYY-MM-DD'))}
                      clearIcon={null}
                      className={Boolean(formik.touched?.briefProposalDueDate) && Boolean(formik.errors?.briefProposalDueDate) ? 'form-control  startFilter  border-danger ' : 'form-control  startFilter '}
                      value={new Date(formik.values.briefProposalDueDate)}
                      calendarIcon={<img className='calendar-icon' src={calendarIcon} />}
                      onChange={(value) => {
                        formik.setFieldValue('briefProposalDueDate', value);
                      }}
                    />
                    <InputErrorMessage error={formik.touched.briefProposalDueDate && formik.errors.briefProposalDueDate} marginBottom={-5} />
                  </div>
                </div>
                <div className=''>
                  <label className='form-label'>
                    Industry
                  </label>
                  <Select
                   className={Boolean(formik.touched?.industry) && Boolean(formik.errors?.industry) ? 'select border-select-danger ' : 'select '}
                    defaultValue={defaultindustry}
                    options={industryOption}
                    name='industry'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('industry', value.value);
                    }}
                  />
                  {/* <select
                    className='form-select'
                    name='industry'
                    value={formik.values.industry}
                    onChange={handleChange}>
                    <option hidden>Industry</option>
                    {formSelects?.industry &&
                      formSelects?.industry?.map((el, i) => {
                        return (
                          <option value={el.name} key={i}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select> */}
                  <InputErrorMessage error={formik.touched.industry && formik.errors.industry} marginBottom={-5} />
                </div>
              </div>
            </>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{ width: '100%' }} onClick={closeModal}>
                Cancel
              </button>
              <button type='submit' className='button-primary' disabled={updating} style={{ width: '100%' }}>
                {loader || updating ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader />
                  </div>
                ) : (
                  briefDetails ? 'Update' : 'Start Brief'
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
