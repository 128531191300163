import React, { useState, useEffect } from 'react';
const countries = require('i18n-iso-countries');
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import AddButton from '../../components/common/buttons/AddButton';
import { getUserApi } from '../../apis';
import AdminLayout from '../../components/admin-layout';
import { UserContext, EditFormContext } from '../../appContext';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseBriefListComponent from '../../components/TypesenseList/TypesenseBriefListComponent';
import SimpleModal from '../../components/Modals/SimpleModal.tsx';
import EditBrief from '../Briefs/EditBrief.js';
import { allClientData } from '../../utils/helperFunctions.js';
import {
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  getMasherShareApi,
} from '../../apis/index.js';
import axios from 'axios';

export default function BriefListView({currentUser}) {
  
  const [startBrief, setStartBrief] = useState(false);
  const [loader, setLoader] = useState(false);
  const [client, setClient] = useState({ data: null, loading: true });
  const [formSelects, setformSelects] = useState({ region: '', industry: '', engagementType: '', category: '', client: '', loading: true });
  const [mashHqUsers, setMashHqUsers] = useState();

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countryName = countries.getNames('en', {select: 'official'});
  const countryCode = countries.getNumericCodes();
  const countriesList = Object.keys(countryCode).map((code, i) => {
    const iso = countryCode[code];
    return {
      id: code,
      countryISO: iso,
      countryName: countryName[iso]
    };
  });
  const countriesListSorted = countriesList.sort((a, b) => {
    if (a.countryName < b.countryName) {
      return -1;
    } else {
      return 1;
    }
    return 0;
  });

  const getHqUsers = async () => {
    await axios.get(`${process.env.REACT_APP_HOST_NAME}/collections/hqusers/documents/search?q=*&query_by=`, {
      headers: {
        'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
      }
    })
      .then((res) => setMashHqUsers(res.data))
      .catch((err) => console.error(err));
  };

  const fetchSelectData = async () => {
    setformSelects({ loading: true });
    try {
      const selectResponse = {
        regionRes: await fetchRegionApi(),
        industryRes: await fetchIndustryApi(),
        engagementRes: await fetchEngagementTypeApi(),
        cateRes: await fetchCategoryApi(),
        clientRes: await fetchClientApi(),
      };
      
      setformSelects({
        region: selectResponse?.regionRes.data,
        country: countriesListSorted,
        industry: selectResponse?.industryRes.data,
        engagementType: selectResponse?.engagementRes.data,
        category: selectResponse?.cateRes.data,
        client: selectResponse?.clientRes.data,
        loading: false,
      });
    } catch (error) {
      setformSelects({ loading: false });
    }
    
  };
  
  useEffect(() => {
    fetchSelectData();
    getHqUsers();
    const searchFilter = { search: '', page: 1, limit: '' };
    allClientData({ ...searchFilter }, setClient);
  }, []);

  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'briefsv2',
          q:'*',
          query_by: 'companyName, name, owner.firstName,contributors.userId',
          sort_by   : 'createdAt:desc',
          filter_by: 'status:!=project',
          per_page: 25,
          facet_by: 'status, owner.firstName, companyCountry.countryName, feeAllocation.totalCost',
          max_facet_values: 40,
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  const tableHeaders = [
    {
      title: 'Brief Name'
    },
    {
      title: 'Company'
    },
    {
      title: 'Budget'
    },
    {
      title: 'Brief Owner'
    },
    {
      title: 'Status'
    },
    {
      title: 'Create Date'
    },
    {
      title: 'Proposal Due Date'
    }
  ];
  const columnWidth = () => {
    return `${100 / tableHeaders.length}%`;
  };
  
  const startBriefDetails = () => {
    setStartBrief(!startBrief);
  }; 

  return (
    <AdminLayout>
      <UserContext.Provider value>
        <PageHeader title='Briefs' buttonsArray={[
          <AddButton
            title='Create Brief' 
            altText='craete brief'
            buttonType='primary'
            buttonFunction='openModal'
            onClickEvent={startBriefDetails}
          />
        ]}/>
        <div>
          <article>
            <main>
              <TypesenseBriefListComponent
                searchClient={searchClient}
                headers={tableHeaders}
                columnWidth={columnWidth()}
                hqUsers={mashHqUsers}
                showSearchOptions={true}
                showStatusOptions={true}
                showBriefOwnerOptions={true}
                showPropDueDateOptions={true}
                blockTitle="Briefs"
                tablePaddingRequired={true}
              />
            </main>
          </article>
        </div>
        <EditFormContext.Provider
          value={{
            formSelects,
            client,
            allClientData,
            loader
          }}>
          {
            startBrief && 
              <SimpleModal title='Brief Start' description='All fields are mandatory' closeModal={startBriefDetails} >
                <EditBrief briefDetails={null} closeModal={startBriefDetails} currentUser={currentUser}/>
              </SimpleModal>
          }
        </EditFormContext.Provider>
      </UserContext.Provider>
    </AdminLayout>
  );
}
