import React, {useState,useMemo} from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import expandView from '../Icons/expandView.svg';
import minimiseView from '../Icons/minimiseView.svg';
import MashCard from '../components/cards/MashCard';
import { XCircleFill,X, Percent, Calendar2DayFill, Calendar2MinusFill, GenderAmbiguous, Geo, GeoAltFill, GlobeAsiaAustralia } from 'react-bootstrap-icons';
import Bannerimg from '../Assets/img/cards/banner3.png';
import { createInfluencersecSchema } from './Validation/ValidationSchema';
import { useFormik } from "formik";
import { InputErrorMessage } from "../components/errorMessages";
import toast from "react-hot-toast";
import { EditProfileLoader } from "../components/common/loader";
import countryList from "react-select-country-list";
import Select from "react-select";
import Flatpickr from 'react-flatpickr';
export default function InflencerPreview({ data }) {
  const { REACT_APP_PROFILEPREVIEWIFRAME } = process.env;
 
  // alert(JSON.stringify(data))
  const hitTest={
    Name:data?.Name,
    HandleID:data?.HandleID,
    Photo:data?.Photo,
    posts:data?.posts,
    Followers:data?.Followers,
    Following:data?.Following,
    ErPercentage: data?.ErPercentage,
    DOB: data?.DOB,
    Gender: data?.Gender,
    Location: data?.Location,
    Country: data?.Country,

  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    let suffix = 'th';
    
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }
  
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    return `${day}${suffix} ${month}`;
  }
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ];
  
  const DetailsContainer=({title,data,icon})=>{
    return(
      <div className='d-flex gap-2 items-center justify-start w-full ml-3 mb-2'>
        <div className="rounded-sm p-1 h-8 w-12 border border-black justify-center items-center flex bg-blue-900 text-white shadow-sm">
        <div className='text-sm/[7px]'>{title}</div>{icon}
      </div>
      <div className='capitalize'>{data || '-'}</div>
     </div>
    )
  }
  function formatInstagramPostNumber(number) {
    if (number < 1000) {
      return number.toString(); // Numbers less than 1000 are displayed as-is
    } else if (number < 1000000) {
      return Math.floor(number / 1000) + 'k'; // Numbers between 1000 and 999,999 are displayed in "k" format
    } else {
      return Math.floor(number / 1000000) + 'm'; // Numbers equal to or greater than 1,000,000 are displayed in "m" format
    }
  }
  const selectedCountry = countryOptions.find(option => option.value === hitTest.Country);
const label = selectedCountry ? selectedCountry.label : '';
  return (
    <>
      <form >
        <div
          className="modal"
          id="influencerPreview"
          tabIndex={-1}
          aria-labelledby="influencerPreview"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" style={{maxWidth: '400px' }}>
            <div className="modal-content shadow-3">
              <div className="modal-body w-full" style={{padding:'0px'}}>
              <div className="cursor-pointer modalclose" data-bs-dismiss="modal" aria-label="Close">
                 <X color='#fff' size={29} fontWeight={'bold'} />
              </div>
                <div className='flex justify-center items-center'>
                <div className="dark:!bg-navy-800 shadow-shadow-500 shadow-3xl rounded-primary relative mx-auto flex h-full w-full max-w-[550px] flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-white dark:shadow-none">
                <div className="relative mt-1 flex h-24 w-full justify-center rounded-sm bg-cover" style={{ backgroundImage: `url("${Bannerimg}")` }}>
                  <div className="absolute -bottom-12 flex h-[88px] w-[88px] items-center justify-center rounded-sm border-[6px] border-white bg-white">
        <img className="h-full w-full rounded-sm" src={hitTest.Photo} alt="" />
        </div>
      </div>
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-bluePrimary text-xl font-bold">{hitTest.Name}</h4>
        <p className="text-lightSecondary text-base font-normal">{hitTest.HandleID}</p>
      </div>
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-bluePrimary text-lg font-bold">{formatInstagramPostNumber(hitTest.posts)}</h3>
          <p className="text-lightSecondary text-sm font-normal">Posts</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-bluePrimary text-lg font-bold">{formatInstagramPostNumber(hitTest.Followers)}</h3>
          <p className="text-lightSecondary text-sm font-normal">Followers</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-bluePrimary text-lg font-bold">{formatInstagramPostNumber(hitTest.Following)}</h3>
          <p className="text-lightSecondary text-sm font-normal">Following</p>
        </div>
      </div>
      <div className="h-px my-2  bg-gray-200 border-0 dark:bg-gray-700 w-full"></div>
      <DetailsContainer title="ER" icon={<Percent size={10} />}  data={hitTest.ErPercentage || ''} />
      <DetailsContainer icon={<Calendar2MinusFill />} data={hitTest.DOB ? formatDate(hitTest.DOB) : ''} />
      <DetailsContainer icon={<GenderAmbiguous size={18} />} data={hitTest.Gender || ''} />
      <DetailsContainer icon={<GeoAltFill />} data={hitTest.Location || ''} />
      <DetailsContainer icon={<GlobeAsiaAustralia />} data={label} />
       </div>
                {/* <MashCard hit={hitTest}/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}