import React, { useEffect, useState, useMemo,useContext } from 'react';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import AddButton from '../../components/common/buttons/AddButton';
import AdminLayout from '../../components/admin-layout';
import { UserContext } from '../../appContext';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseCompanyListComponent from '../../components/TypesenseList/TypesenseCompanyListComponent';
import SimpleModal from '../../components/Modals/SimpleModal';
import CreateUser from './CreateUser';
import { AppContext } from '../../appContext';
import EditUser from './EditUser.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import {
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  fetchCountrycodeApi,
  getUserApi,
  updateUsersActiveDeactiveApi
} from "../../apis/index.js";
import { EditFormContext } from '../../appContext';
import TypesenseUserListComponent from '../../components/TypesenseList/TypesenseUserListComponent.js';
import toast from 'react-hot-toast';
export default function UserListView() {
  const [startuser, setstartuser] = useState(false);
  const startuserdetails = () => {
    setstartuser(!startuser);
  
  }; 
  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;
  const [formSelects, setformSelects] = useState({
    region: "",
    industry: "",
    engagementType: "",
    category: "",
    client: "",
    loading: true,
  });
  const fetchSelectData = async () => {
    setformSelects({ loading: true });
    try {
      const selectResponse = {
        regionRes: await fetchRegionApi(),
        countryRes: await fetchCountrycodeApi(),
        industryRes: await fetchIndustryApi(),
        engagementRes: await fetchEngagementTypeApi(),
        cateRes: await fetchCategoryApi(),
        clientRes: await fetchClientApi(),
        currentUser: await getUserApi(),
      };

      setformSelects({
        region: selectResponse?.regionRes.data,
        country: selectResponse?.countryRes.data.countries,
        industry: selectResponse?.industryRes.data,
        engagementType: selectResponse?.engagementRes.data,
        category: selectResponse?.cateRes.data,
        client: selectResponse?.clientRes.data,
        currentUser: selectResponse?.currentUser.data,
        type:selectResponse?.clientRes?.data,
        loading: false,
      });
    } catch (error) {
      setformSelects({ loading: false });
    }
  };
  useEffect(() => {
    fetchSelectData();
  }, [loggedInUser]);
  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'hqusers',
          query_by: 'role,firstName,lastName,email',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  const tableHeaders = [
    {
      title: 'User'
    },
    {
      title: 'Email'
    },
   
    {
        title: 'Created Date'
    },
    {
      title: 'Last Login'
    },
    {
      title: 'Edit'
    },
    {
        title: 'Disable Login'
    },
   
   
  ];
  const [currentdata,setcurrentdata]=useState();
  const [updateuser, setupdateuser] = useState(false);
    const startuserdetailsupdate = (id,data) => {
      setupdateuser(!updateuser);
      setcurrentdata(data);
      
    }; 
  const columnWidth = () => {
    return `${100 / tableHeaders.length}%`;
  };
  const [activeloader,setactiveloader]=useState(false);
  const [deactiveuser, setdeactiveuser] = useState(false);
  const [currentuser, setcurrentuser] = useState(false);
  const ActiveDeActiveUser=async()=>{
    setactiveloader(true);
    try {
      const payload={
        "email":currentuser,
        "isActive": false
    }

       const res = await updateUsersActiveDeactiveApi(payload);
       setactiveloader(false);
      if (res.status === 200 || 201) {
        toast.success(res?.data?.message, { id: '001' });
       setdeactiveuser(false);
       setcurrentuser('');
      } else {
        toast.error(res?.data?.message, { id: '001' });
      }
    } catch (err) {
      toast.error(err, { id: '001' });
    }
  }
  const opendeactivemodal=(email)=>{
    setdeactiveuser(true);
    setcurrentuser(email);
  }

  return (
    <AdminLayout>
      <UserContext.Provider>
      <PageHeader title='User Management' buttonsArray={[
          <AddButton
            title='Create User' 
            altText='Create User'
            buttonType='primary'
            buttonFunction='openModal'
            onClickEvent={startuserdetails}
          />
        ]}/>
       
        <div className='container-fluid'>
          <article>
            <main className='pt-6 d-flex flex-column'>
              <TypesenseUserListComponent
                searchClient={searchClient}
                updateuser={updateuser}
                setupdateuser={setupdateuser}
                startuserdetailsupdate={startuserdetailsupdate}
                currentdata={currentdata}
                setcurrentdata={setcurrentdata}
                ActiveDeActiveUser={ActiveDeActiveUser}
                headers={tableHeaders}
                opendeactivemodal={opendeactivemodal}
                columnWidth={columnWidth()}
                blockTitle='Users'
                tablePaddingRequired={false}
              /> 
            </main>
          </article>
        </div>
        <EditFormContext.Provider
          value={{
            formSelects,
          }}
        >
           {
            startuser && 
              <SimpleModal title='Create User' description='All fields are mandatory' closeModal={startuserdetails} >
                <CreateUser  closeModal={startuserdetails} />
              </SimpleModal>
          }
          {
            updateuser && 
              <SimpleModal title='Edit User' description='All fields are mandatory' closeModal={startuserdetailsupdate} >
                <EditUser data={currentdata} id=''  closeModal={startuserdetailsupdate} />
              </SimpleModal>
          }
           {
           deactiveuser &&  
            <SimpleModal title='Stop Login' isWarning={true} noScroll={true} closeModal={() => {setdeactiveuser(false);}} >
              <div className='row'>
                <div>
                  <label className='form-label' style={{textAlign: 'center', width: '100%'}}>
                    Are you sure you want to Stop the Login?
                  </label>
                </div>
                <div className='brief-button-container'>
                  <button type='button' className='button-close' style={{width: '100%'}} onClick={() => {
                    setdeactiveuser(false);
                    setcurrentuser('');
                    }}>
                    Cancel
                  </button>
                  <button  className='button-warning ' onClick={()=>{ActiveDeActiveUser()}} disabled={activeloader} style={{width: '100%'}}>
                  {activeloader ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader style={{}}/>
                  </div>
                ) : (
                  'Disable Login'
                )}
                  </button>
                </div>
              </div>
            </SimpleModal>
          }
        </EditFormContext.Provider>
       
          
      </UserContext.Provider>
    </AdminLayout>
  );
}
