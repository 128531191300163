import React, {useState, useEffect} from 'react';
import './tableList.css';
import tickGreen from '../../Icons/tickGreen.svg';
import Avatar from '../Avatar/Avatar';
import AddButton from '../common/buttons/AddButton';
import { PencilSquare } from 'react-bootstrap-icons';
import { PersonFillLock } from 'react-bootstrap-icons';
import SimpleModal from '../Modals/SimpleModal';
import CreateUser from '../../Pages/Users/CreateUser';
import EditUser from '../../Pages/Users/EditUser';
import { EditProfileLoader } from '../common/loader';
import Moment from 'react-moment';
import { PersonCheckFill } from 'react-bootstrap-icons';
function TableEventLog({hit, columnWidth}) {
   // const name=hit.firstName+" "+hit.lastName;
    
  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item'>
         
          <div style={{width: columnWidth}} className='table-list-font-light'>{hit.campaignsName}</div>
          <div style={{width: columnWidth}} className='table-list-font-light'>{hit.Name}</div>
          <div style={{width: columnWidth}}  className='table-list-font-light overflowtbl'><a href='`https://www.instagram.com/${hit.HandleID}`'>
            {`https://www.instagram.com/${hit.HandleID}`}</a></div>
            <div style={{width: columnWidth}} className='table-list-font-light'>{hit.posts}</div>
            <div style={{width: columnWidth}} className='table-list-font-light'>{hit.Followers}</div>
            <div style={{width: columnWidth}} className='table-list-font-light'>{hit.Following}</div>
            <div style={{width: columnWidth}} className='table-list-font-light'>
                <div className='d-flex'>
                    <div className=''>
                      <div>1-500 Acc</div>
                      <div>100%</div>
                    </div>
                    <div className=''>
                      <div>500-2k</div>
                      <div>0%</div>
                    </div>
                    <div className=''>
                      <div>2k-All</div>
                      <div>0%</div>
                    </div>
                </div>
            </div>
            <div style={{width: columnWidth}} className='table-list-font-light'><Moment format="DD/MM/YYYY" date={hit.projectExpectedStartDate} /></div>
            <div style={{width: columnWidth}} className='table-list-font-light'><Moment format="DD/MM/YYYY" date={hit.projectExpectedEndDate} /></div>
            <div style={{width: columnWidth}} className='table-list-font-light'><Moment format="DD/MM/YYYY" date={hit.updatedAt} /></div>
         
         
        </div>
      </div>
          
          
    </div>
  );
}

export default TableEventLog;