import React, {useState} from 'react';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
// import {dummyBriefData as briefData} from '../PricingTable/dummyBriefData';
import RoleCard from './RoleCard';
import { Loader } from '../../../components/common/loader';
import './masherTeamUpTable.css';


export default function MasherTeamUpTable({ briefData, refreshBrief, convertToProject }) {

  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'influencer',
          sort_by: 'createdAt:asc',
          query_by: 'Name, createdAt,HandleID',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  return (
    <>
      <div className='project-mashup-table-title-block'>Influencers Team</div>
      <div className='mashup-table-header-row'>

        <div  className='role-card-masher-empty-column'></div>
        <div className='role-card-masher-details-column'>
          <div className='role-card-masher-details'>
            <div>
              <p>Name</p>
            </div>
          </div>
                      
          {/* <div className='role-card-status'>
            <p>Status</p>
          </div> */}

          <div className='role-card-preference'>
            <p>Preference</p>
          </div>
        </div>
        <div className='role-card-action-column'>

        </div>
      </div>
      <div className='mashup-table-card-container'>
        {
          briefData.deliverables ?
            briefData.deliverables?.map((deliverable, i) => {
              return <RoleCard convertToProject={convertToProject} key={i} 
              refreshBrief={refreshBrief} 
              deliverable={deliverable} 
              briefId={briefData._id} 
              index={deliverable.deliverableId} 
              searchClient={searchClient} 
              deliverables={briefData.deliverables} />;
            })
            :
            <p>Please Add deliverables before adding Platforms.</p>
        }
      </div>
    </>
  );

}