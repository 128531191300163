import React, {useState, useEffect} from 'react';
import './tableList.css';
import tickGreen from '../../Icons/tickGreen.svg';
import Avatar from '../Avatar/Avatar';
import AddButton from '../common/buttons/AddButton';
import { PencilSquare } from 'react-bootstrap-icons';
import { PersonFillLock } from 'react-bootstrap-icons';
import SimpleModal from '../Modals/SimpleModal';
import CreateUser from '../../Pages/Users/CreateUser';
import EditUser from '../../Pages/Users/EditUser';
import { EditProfileLoader } from '../common/loader';
import Moment from 'react-moment';
import { PersonCheckFill } from 'react-bootstrap-icons';
function TableListUser({hit, columnWidth,opendeactivemodal,startuserdetailsupdate,ActiveDeActiveUser,activeloader,setactiveloader}) {
    const name=hit.firstName+" "+hit.lastName;
    
  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item'>
          <div style={{width: columnWidth}} className='table-list-font-bold'>
            <div className='d-flex items-center gap-2'>
              <Avatar name={name} url={hit.profilePic}  borderColor='white'/>
              <div>{name}</div>
            </div>
            
          </div>
          <div style={{width: columnWidth}} className='table-list-font-light'>{hit.email}</div>
          <div style={{width: columnWidth}} className='table-list-font-light'> 
          <Moment format="DD/MM/YYYY" date={hit.createdAt} /></div>
          <div style={{width: columnWidth}} className='table-list-font-light'> 
           {hit?.lastLogin ? <Moment format="DD/MM/YYYY" date={hit.createdAt} /> :'-'}</div>
          <div style={{width: columnWidth}} className='table-list-font-light'>
            <PencilSquare  size={25}  onClick={()=>{startuserdetailsupdate(hit._id,hit)}} style={{ cursor: 'pointer' }}  />
          </div>
          <div style={{width: columnWidth}} className='table-list-font-light'>
          {hit.isActive ? 
          <div className='d-flex items-center'>
          <PersonFillLock size={25}  onClick={()=>{opendeactivemodal(hit.email);}} style={{ cursor: 'pointer' }}  />
          
          </div>
          :
          'Login stopped'
          }
          
          </div>
         
         
        </div>
      </div>
          
          
    </div>
  );
}

export default TableListUser;