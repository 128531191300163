import axios from 'axios';
import { API_BASE_URL } from '../utils/baseurl';

/*
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.status === 401) {
      localStorage.clear();
      // window.location.href = "/sign-in";
    } else {
      throw error;
    }
  }
);*/

export const NOTOKENPOST = (path, data) => {
  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const NOTOKENPUT = (path, data) => {
  return axios.put(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getApiWithoutToken = (path) => {
  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const POST = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GET = (path) => {
  const token = localStorage.getItem('token');

  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DELETE = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.delete(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const PUT = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.put(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
export const PATCH = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fileUpload = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

//file Upload with progress...
// export const fileUploadwithprogress = (path, data,onUploadProgress,file) => {
//   const tokens = localStorage.getItem('token');

//   return axios.post(API_BASE_URL + path, data, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//       Authorization: `Bearer ${tokens}`,
//     },
//      // Check if onUploadProgress is provided before adding it to the config
//      ...(onUploadProgress && { onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, file) }),
  
//   });
// };
export const fileUploadwithprogress = (path, data,onUploadProgress,file) => {
  const tokens = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${tokens}`,
    },
     // Check if onUploadProgress is provided before adding it to the config
     ...(onUploadProgress && { onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, file) }),
  
  });
};

//delete api ............
export const DeleteApi = (path) => {
  const token = localStorage.getItem('token');

  return axios.delete(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GETINSTAGRAM = (path) => {
  return axios.get(path, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
