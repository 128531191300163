
import React, { useState } from 'react';
import { useFormik } from 'formik';
import './masherTeamUpTableProject.css';
import { EditProfileLoader } from '../../../components/common/loader';
import Avatar from '../../../components/Avatar/Avatar';
import { InputErrorMessage } from '../../../components/errorMessages';
import ReactPlayer from 'react-player';
import { HeartFill,ChatFill,EyeFill, Eye, ChatLeftHeartFill } from 'react-bootstrap-icons';
import { CreatePostLink } from '../../Validation/ValidationSchema';
export default function AddPost({ closeModal, userId }) {
  const [page, setPage] = useState("page1");
  const [loader, setLoader] = useState(false);

  const formik1 = useFormik({
    initialValues: {
      link: "",
    },
    validationSchema:CreatePostLink,
    onSubmit: async (values) => {
      setLoader(true);
      // Submit logic for form 1 goes here
      setPage("page2");
      setLoader(false);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      // file:'https://scontent-xsp1-1.cdninstagram.com/o1/v/t16/f1/m82/CF43CC4D4B570911DDA4D14010B40FB8_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzIuNzIwLmRhc2hfYmFzZWxpbmVfMV92MSJ9&_nc_ht=scontent-xsp1-1.cdninstagram.com&_nc_cat=106&vs=827285029220275_2422261134&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9DRjQzQ0M0RDRCNTcwOTExRERBNEQxNDAxMEI0MEZCOF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dOZ21ZaGk2Z1hhUkRjWUNBTTVJeDh6XzkwdExicV9FQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJvC10dbiwvk%2FFQIoAkMzLBdAVkKPXCj1wxgSZGFzaF9iYXNlbGluZV8xX3YxEQB1AAA%3D&ccb=9-4&oh=00_AfDOvREESm_ES5uEquoOuX-h-7PKflxh-xai5VQk0hKyiw&oe=65D8DD1B&_nc_sid=1d576d&_nc_rid=715a0664d5',
      // media_type:'VIDEO',
      // file:'https://scontent-xsp1-1.cdninstagram.com/v/t51.29350-15/427447034_1783412505482595_7457422430645262425_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=18de74&_nc_ohc=9BkWNQZIVkAAX-ZwGe_&_nc_ht=scontent-xsp1-1.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfAAhjZ-6SItedTguoTdtUX3xNk3LOBD5P2t7qG0YaeFog&oe=65DBE9E9',
      // media_type:'IMAGE',
      file:'https://scontent-xsp1-2.cdninstagram.com/v/t51.29350-15/427717158_910704043874697_5498634101458274328_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=18de74&_nc_ohc=nyTeckmb-GgAX8mZ5_H&_nc_ht=scontent-xsp1-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfAUTfX7mTScjOB_IWET5qu4xR0YfwZ3J0bjaNAMD3lpHA&oe=65DBE9E5',
      media_type:'CAROUSEL_ALBUM',
      like:'12',
      comment:'22',
      views:'40',
     
    },
    onSubmit: async (values) => {
      setLoader(true);
      // Submit logic for form 2 goes here
      setLoader(false);
    },
  });

  const handlePreviewClick = () => {
    formik1.handleSubmit(); // Submit form 1
  };
  const handleChange = (e) => {
    formik1.handleChange(e);
  };

  return (
    <>
      {page === "page1" && (
        <div>
          <form onSubmit={formik1.handleSubmit}>
            <div className="row link-popup">
              <div>
                <label className="form-label">Enter Post Link</label>
                <input
                  type="text"
                  className={
                    Boolean(formik1.touched?.link) &&
                    Boolean(formik1.errors?.link)
                      ? "form-control border-danger"
                      : "form-control "
                  }
                  placeholder="Enter Post Link"
                  name="link"
                  value={formik1.values.link}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik1.touched.link && formik1.errors.link}
                  marginBottom={-5}
                />
              </div>
              <div className="brief-button-container">
                <button type="button"  className="button-close w-full" onClick={closeModal}>Cancel</button>
                <button type="button"  className="button-primary w-full" onClick={handlePreviewClick}>
                  {loader ?  <div className="text-xs text-center">
                      <EditProfileLoader />
                    </div> : "Preview"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      {page === "page2" && (
        <div>
          <form onSubmit={formik2.handleSubmit}>
            <div className="row link-popup">
            <div className="flex flex-col gap-2.5 justify-center items-center my-3 ">
           
            <div className="instagram-img">

            </div>
                 {
                   formik2.values.media_type &&
                   <>
                    {
                      formik2.values.media_type=="VIDEO" ? (
                        <div style={{width: 300,height: 200,borderRadius: '5%',background:'#fff'}}>
                        <ReactPlayer
                          url={formik2.values.file}
                          width='100%'
                          height='100%'
                        />
                      </div>
                     ) :(
                      <img
                      className="instagram-img"
                      alt='...'
                      src={formik2.values.file}
                      onError={(event) => {
                      event.target.src = ProfileDefault;
                      event.onerror = null;
                      }}
                      />
                      )
                    }
                    
                   </>  
                  
 
                 }
                 <span className="handle-id">{formik2.values.handleid}</span>
                 <span className="handle-username">{formik2.values.name}</span>
                  <div className="flex-cotainer-insta">
                  <div className="flex-inshits">
                        <div className="flex-insight-num d-flex items-center gap-1"> <ChatLeftHeartFill size={20} /> {formik2.values.views}</div>
                   </div>
                   <div className="flex-inshits">
                        <div className="flex-insight-num d-flex items-center gap-1"> <HeartFill size={20} /> {formik2.values.like}</div>
                   </div>
                   <div className="flex-inshits">
                        <div className="flex-insight-num d-flex items-center gap-1"> <ChatFill size={20} />{formik2.values.comment}</div>
                   </div>
                 
                   </div>
                
                 
               </div>
            
            <div className="brief-button-container">
            <button type="button"  className="button-close w-full" onClick={() => setPage("page1")}>Back</button>
                <button type="button"  className="button-primary w-full" onClick={handlePreviewClick}>
                  {loader ?  <div className="text-xs text-center">
                      <EditProfileLoader />
                    </div> : "Save"}
                </button>
            </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
