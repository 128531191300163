
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

const URL = /^(?:(?:https?|http|www)\:\/\/)?(?:[a-zA-Z0-9\-]+\.)+(?:(?:[a-zA-Z]{2,4})|(?:[a-zA-Z0-9\-]+))(?:\:[0-9]+)?(?:[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~])*$/;

export const CreateCompanyValidation = yup.object({
    companyname: yup.string().required('Company Name is required.'),
    companyemail: yup.string().required('Email is required.').email('Invalid email format.'),
    company_phoneno: yup.string().test('validator-custom-name', function (value) {
      if (value) {
        if (!isValidPhoneNumber(value)) {
          return this.createError({
            message: 'Invalid Phone Number',
          });
        } else {
          return true;
        }
      } else {
        return this.createError({
          message: 'Phone Number can\'t be Empty',
        });
      }
    }),
    country: yup.string().required('Country is required.'),
    companyloc: yup.string().required('Location is required.'),
    industry:yup.string().required('Industry is required.'),
    region:yup.string().required('Region is required.'),
    type:yup.string().required('Type is required.'),
    websiteLink:yup.string().required('Website Link is required.'),
    notes:yup.string().required('Notes is required.'),
  
  });

export  const createInfluencerSchema = yup.object({
  handleId: yup.string().required('This field is required'),
  // Followers: yup.string().required(),
  // Following: yup.string().required(),
  // Platform: yup.string().required(),
});
export  const createInfluencersecSchema = yup.object({
  epercentage: yup.mixed().required('This field is required'),
  birthDate: yup.mixed().required('This field is required'),
  gender: yup.mixed().required('This field is required'),
  companyloc: yup.mixed().required('This field is required'),
  country: yup.mixed().required('This field is required'),
});
export  const CreatePostLink = yup.object({
  link: yup.string().required('This field is required'),
  // Followers: yup.string().required(),
  // Following: yup.string().required(),
  // Platform: yup.string().required(),
});
export  const createUserSchema = yup.object({
  Fname: yup.string().required('This field is required'),
  Lname: yup.string().required('This field is required'),
  email: yup.string().required('This field is required'),
  role: yup.string().required('This field is required'),
  profilePic: yup.string().required('This field is required'),
  // Followers: yup.string().required(),
  // Following: yup.string().required(),
  // Platform: yup.string().required(),
});
export const BriefModalSchema = yup.object({
  companyId: yup.string().required('Company ID is required'),
  companyName: yup.string().required('Company Name is required'),
  briefName: yup.string().required('Brief is required'),
  briefOwner: yup.string().required('Brief is required'),
  briefExpectedStartDate: yup.string().required('Expected Start Date is required'),
  briefProposalDueDate: yup.string().required('Expected End Date is required'),
  industry: yup.string().required('Industry is required'),
  country: yup.string().required('Country is required'),
  currency: yup.string().required('Currency is required')
});