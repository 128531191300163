import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Loader } from '../../../../components/common/loader';
import { InputErrorMessage } from '../../../../components/errorMessages';
import { allRolesApi, addRoleApi } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';

export default function AddRole({ briefId, deliverable, closeModal }) {
  const [updating, setUpdating] = useState(false);
  const { deliverableId } = deliverable;
  const [roles, setRoles] = useState<any>([]);
  const[Platformselect,setplatformselect]=useState<any>([
    {id:0,platform:'Instagram'}
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getAllRoles() {
      setLoading(true);
      const rolesRes = await allRolesApi();
      setLoading(false);
      if (rolesRes.status === 200) {
        if (deliverable.role && deliverable.role.length > 0) {
          const filtered = rolesRes.data.allRoles.filter((role) => {
            if(deliverable.role.find((r) => r.roleId === role.id)) {
              return false;
            }
            return true;
          });
          setRoles(filtered);
        } else {
          setRoles(rolesRes.data.allRoles);
        }
      }
    }
    getAllRoles();
  }, []);

  const addRoleSchema = yup.object({
    roleId: yup.string().required('Please select a platform to continue')
  });

  const formik = useFormik({
    initialValues: {
      roleId: '',
    },
    validationSchema: addRoleSchema,
    onSubmit: async (values) => {
      try {
        setUpdating(true);

        let roleIndex = roles.findIndex((role: any) => role.id === values.roleId);

        if (roleIndex < 0) {
          roleIndex = 0;
        }

        const res = await addRoleApi({
          deliverableId,
          id: briefId,
          roleId: values.roleId,
          roleName: roles[roleIndex]?.roles
        });
        setUpdating(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });          
          closeModal();
        } else {

        }
      } catch(e) {
        closeModal();
      }
    },
  });
  
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>    
    {loading ? (
        <div className='text-xs text-center'>
          <Loader />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {
            roles.length > 0 ? (
          
          <div className='row'>
            <div>
              <label className='form-label'>
                Platforms
              </label>
              <select
                className='form-select'
                name='roleId'
                value={formik.values.roleId}
                onChange={handleChange}>
                <option hidden>Platforms</option>
                
                {roles.length > 0 &&
                roles?.map((el: any, i) => {
                  return (
                    <option value={el.id} key={i}>
                      {el.roles}
                    </option>
                  );
                })}
              </select>
              <InputErrorMessage error={formik.touched.roleId && formik.errors.roleId} marginBottom={-5} />
            </div>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
                Cancel
              </button>
              <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
                {updating ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader />
                  </div>
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
            ) :(
              <>
              
              <div className='text-center  border-dashed border-2 pl-10 pr-10 pt-5 pb-5 m-4  '>
                    No Platforms availalbe
                  </div>
                  
              </>
            )
                }
        </form>
      )
    }
    </>
  );
}
