import React, { useEffect, useState, useMemo, useContext } from 'react';
import toast from 'react-hot-toast';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
import { autoHeightText, style } from '../../utils/helperFunctions';
import * as yup from 'yup';
import { Loader } from '../../components/common/loader';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import calendarIcon from '../../Icons/calendarIcon.svg';
import Currency from '../../utils/Currency.json';
import { InputErrorMessage } from '../../components/errorMessages';
import Select from 'react-select';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader';
import { brandOptions, getLastElementId } from '../../utils/helperFunctions';
import countryList from 'react-select-country-list';
import { editBriefApi, getAllUsers, getAllInternalMashers, fetchIndustryApi, createBriefApiV2, editBriefApiV2, syncLiveblocks, getUserApi, createCompanyApi } from '../../apis/index';
import { getHqUsersFromTypesense, getCompaniesFromTypesense } from '../../apis/typesenseApis';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, Configure, useInstantSearch } from 'react-instantsearch';
import PhoneInput from 'react-phone-number-input';
import { CreateCompanyValidation } from '../Validation/ValidationSchema';
import { EditFormContext } from '../../appContext';
import industry from '../../utils/industry.json';
import Region from '../../utils/region.json';
import clientType from '../../utils/clientType.json';
export default function Createcompany({ closeModal }) {
  const [updating, setUpdating] = useState(false);
  const [loader, setLoader] = useState(false);
  const EditContext = useContext(EditFormContext);
  const { formSelects, } = EditContext;
  const countryOptions = useMemo(() => countryList().getData(), []);
  // alert(JSON.stringify(formSelects.industry))
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  const formik = useFormik({
    initialValues: {
      companyname: '',
      companyemail: '',
      companyloc: '',
      country: '',
      company_phoneno: '',
      industry: '',
      type: '',
      region: '',
      websiteLink: '',
      notes: ''
    },
    validationSchema: CreateCompanyValidation,
    onSubmit: async (values) => {
      setLoader(true);
      const { companyname, companyemail, companyloc, country, company_phoneno,type,industry,region,websiteLink,notes } = values
      try {
        const payload={
          "companyName": companyname,
          "companyEmail": companyemail,
          "companyLocation": companyloc,
          "companyCountry": country,
          "Phone": company_phoneno,
          "industry": industry,
          "type": type,
          "region": region,
          "websiteLink":websiteLink,
          "notes":notes,
        }

         const res = await createCompanyApi(payload);
         setLoader(false);
        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });
          closeModal();
        } else {
          toast.error(res?.data?.message, { id: '001' });
        }
      } catch (err) {
        toast.error(err, { id: '001' });
      }
    },
  });
  const industryOption = industry.map(el =>
    ({ value: el.name, label: el.name }));
    const defaultindustry = industryOption?.find(option => option.value === formik.values.industry);
    const RegionOption = Region.map(el =>
      ({ value: el.name, label: el.name }));
      const defaultRegion = RegionOption?.find(option => option.value === formik.values.region);
      const clienttypeOption = clientType.map(el =>
        ({ value: el.name, label: el.name }));
        const defaultclienttype = clienttypeOption?.find(option => option.value === formik.values.type);
  return (
    <>

      <form onSubmit={formik.handleSubmit}>
        <div className='row'>

          <div>
            <label className='form-label'>
              Name of Company
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.companyname) && Boolean(formik.errors?.companyname) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter Company Name'
              name='companyname'
              value={formik.values.companyname}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.companyname && formik.errors.companyname} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
              Email
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.companyemail) && Boolean(formik.errors?.companyemail) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter Email'
              name='companyemail'
              value={formik.values.companyemail}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.companyemail && formik.errors.companyemail} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
              Location
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.companyloc) && Boolean(formik.errors?.companyloc) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter Location'
              name='companyloc'
              value={formik.values.companyloc}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.companyloc && formik.errors.companyloc} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
              Country
            </label>
            <Select
              className={Boolean(formik.touched?.country) && Boolean(formik.errors?.country) ? 'select border-select-danger' : 'select '}
              defaultValue={countryOptions[countryOptions.findIndex(element => element.value === formik.values.country)]}
              options={countryOptions}
              name='country'
              isClearable={false}
              isSearchable={true}
              onChange={(value) => {
                formik.setFieldValue('country', value.value);
              }}
            />
            <InputErrorMessage error={formik.touched.country && formik.errors.country} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
              Company Phone Number
            </label>
            <PhoneInput
              className={Boolean(formik.touched?.company_phoneno && formik.errors?.company_phoneno) ? 'form-control border-danger phoneinp' : 'form-control phoneinp'}
              placeholder="Enter phone number"
              defaultCountry={'AU'}
              international
              value={formik.values.company_phoneno}
              rules={{ required: true }}
              onChange={(e) => {
                formik.setFieldValue('company_phoneno', e || '');
              }}
            />
            <InputErrorMessage error={formik.touched.company_phoneno && formik.errors.company_phoneno} marginBottom={-5} />
          </div>
          <div >
            <label className='form-label'>
              Industry
            </label>
            <Select
                   className={Boolean(formik.touched?.industry) && Boolean(formik.errors?.industry) ? 'select border-select-danger' : 'select '}
                    defaultValue={defaultindustry}
                    options={industryOption}
                    name='industry'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('industry', value.value);
                    }}
                  />
            {/* <select
              className='form-select'
              name='industry'
              value={formik.values.industry}
              onChange={handleChange}>
              <option hidden>Industry</option>
              {formSelects?.industry &&
                formSelects?.industry?.map((el, i) => {
                  return (
                    <option value={el.name} key={i}>
                      {el.name}
                    </option>
                  );
                })}
            </select> */}
            <InputErrorMessage error={formik.touched.industry && formik.errors.industry} marginBottom={-5} />
          </div>
          <div >
            <label className='form-label'>
              Region
            </label>
            <Select
                   className={Boolean(formik.touched?.region) && Boolean(formik.errors?.region) ? 'select border-select-danger' : 'select '}
                    defaultValue={defaultRegion}
                    options={RegionOption}
                    name='region'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('region', value.value);
                    }}
                  />
            {/* <select
              className='form-select'
              name='region'
              value={formik.values.region}
              onChange={handleChange}>
              <option hidden>Region</option>
              {formSelects?.region &&
                formSelects?.region?.map((el, i) => {
                  return (
                    <option value={el.name} key={i}>
                      {el.name}
                    </option>
                  );
                })}
            </select> */}
            <InputErrorMessage error={formik.touched.region && formik.errors.region} marginBottom={-5} />
          </div>
          <div >
            <label className='form-label'>
              Type
            </label>
            <Select
                   className={Boolean(formik.touched?.type) && Boolean(formik.errors?.type) ? 'select border-select-danger' : 'select '}
                    defaultValue={defaultclienttype}
                    options={clienttypeOption}
                    name='type'
                    isClearable={false}
                    isSearchable={true}
                    onChange={(value) => {
                      formik.setFieldValue('type', value.value);
                    }}
                  />
            {/* <select
              className='form-select'
              name='type'
              value={formik.values.type}
              onChange={handleChange}>
              <option hidden>Type</option>
              {formSelects?.type &&
                formSelects?.type?.map((el, i) => {
                  return (
                    <option value={el.name} key={i}>
                      {el.name}
                    </option>
                  );
                })}
            </select> */}
            <InputErrorMessage error={formik.touched.type && formik.errors.type} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
              websiteLink
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.websiteLink) && Boolean(formik.errors?.websiteLink) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter Company websiteLink'
              name='websiteLink'
              value={formik.values.websiteLink}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.websiteLink && formik.errors.websiteLink} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
              Notes
            </label>
            <textarea type='text'
              onKeyUp={(e) => autoHeightText(e)}
              className={Boolean(formik.touched?.notes) && Boolean(formik.errors?.notes) ? 'px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2 border-danger' : 'px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2 '}
              placeholder='Company Notes...'
              rows='3'
              value={formik.values.msg}
              onChange={handleChange}
              name='notes'
            />
            <InputErrorMessage error={formik.touched.notes && formik.errors.notes} marginBottom={-5} />
          </div>
          <div className='brief-button-container'>
            <button type='button' className='button-close' style={{ width: '100%' }} onClick={closeModal}>
              Cancel
            </button>
            <button type='submit' className='button-primary' disabled={updating} style={{ width: '100%' }}>
              {loader ? (
                <div className='text-xs text-center'>
                  <EditProfileLoader />
                </div>
              ) : (
                'Create Company'
              )}
            </button>
          </div>
        </div>
      </form>

    </>
  );
}
