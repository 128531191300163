import React, { useEffect, useState, useMemo, useContext,useRef } from 'react';
import toast from 'react-hot-toast';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
import { autoHeightText, style } from '../../utils/helperFunctions';
import * as yup from 'yup';
import { Loader } from '../../components/common/loader';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import calendarIcon from '../../Icons/calendarIcon.svg';
import Currency from '../../utils/Currency.json';
import { InputErrorMessage } from '../../components/errorMessages';
import Select from 'react-select';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader';
import { brandOptions, getLastElementId } from '../../utils/helperFunctions';
import countryList from 'react-select-country-list';
import { editBriefApi, getAllUsers, getAllInternalMashers, fetchIndustryApi, createBriefApiV2, editBriefApiV2, syncLiveblocks, getUserApi, createCompanyApi, createUserApi } from '../../apis/index';
import { getHqUsersFromTypesense, getCompaniesFromTypesense } from '../../apis/typesenseApis';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, Configure, useInstantSearch } from 'react-instantsearch';
import PhoneInput from 'react-phone-number-input';
import { CreateCompanyValidation, createUserSchema } from '../Validation/ValidationSchema';
import { EditFormContext } from '../../appContext';
import { AppContext } from '../../appContext';
import { assignBucketUrl } from '../../utils/helperFunctions';
import UserDefault from '../../Assets/img/covers/userDefault.png';
import { uploadProfileImgApi } from '../../apis/index';
export default function CreateUser({ closeModal }) {
  const [updating, setUpdating] = useState(false);
  const [loader, setLoader] = useState(false);
  const appContext = useContext(AppContext);
  const { state, fetchUserProfile } = appContext;
  const { loggedInUser } = state;
  const [profile, setProfile] = useState('');
  const countryOptions = useMemo(() => countryList().getData(), []);
  // alert(JSON.stringify(formSelects.industry))
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };
  const formik = useFormik({
    initialValues: {
      Fname:'',
      Lname:'',
      role:'',
      email:'',
      profilePic:''
    },
    validationSchema: createUserSchema,
    onSubmit: async (values) => {
      setLoader(true);
      const { Fname,Lname,email,profilePic,role } = values
      try {
        const payload={
          "email":email,
          "role":role,
          "fname":Fname,
          "lname":Lname,
          "profilePic":profilePic
      }

         const res = await createUserApi(payload);
         setLoader(false);
        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });
          closeModal();
        } else {
          toast.error(res?.data?.message, { id: '001' });
        }
      } catch (err) {
        toast.error(err, { id: '001' });
      }
    },
  });
  const handleFilechange = async (e) => {
    const loadingToast = toast.loading('File Uploading...');

    let file = e.target.files[0];
    const type = file.type.split('/')[1];
    const imageType = ['jpeg', 'jpg', 'png'];
    const validImageType = imageType.includes(type);

    if (!validImageType) {
      formik.setFieldError('profilePic', 'Please upload a valid image : jpeg, png, jpg');
      toast.dismiss(loadingToast);
    } else {
      if (file.size > 5000001) {
        formik.setFieldError('profilePic', 'image size is more than 5 MB');
        toast.dismiss(loadingToast);
        setProfile('');
      } else {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          setProfile(fr.result);
        };
      }
    }
    const formdata = new FormData();
    formdata.append('file', file);
    
    try {
      const response = await uploadProfileImgApi(formdata);
      if (response?.status === 200) {
        toast.success("File uploaded", { id: '001' });
        toast.dismiss(loadingToast);
      }
     formik.setFieldValue('profilePic', response.data.filePath);
    } catch (err) {
      toast.error(err?.response?.message, { id: '001' });
      toast.dismiss(loadingToast);
    }
  };
  return (
    <>

      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
        <div className='d-flex justify-center'>
                    <div style={{ width: '100px', height: '100px' }} className='edit-avtar rounded-circle d-inline'>
                    <input
                // value={formik?.values?.profilePic}
                id='profileImage'
                type='file'
                accept='image/png, image/jpeg, image/jpg'
                name='profile'
                className='form-control'
                onChange={handleFilechange}
                hidden={true}
              />
                      <label
                        className='col-sm-4 bg-repeat-0 bg-position-center bg-size-cover'
                        htmlFor='profileImage'
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          position: 'relative',
                          width: '80px',
                          height: '80px',
                          border: '1px solid rgb(25, 19, 19, 0.15)',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                          backgroundImage: `${
                            profile
                              ? `url(${encodeURI(profile)})`
                              : Boolean(formik.values.profilePic)
                                ? `url(${assignBucketUrl(formik.values.profilePic)})`
                                : `url(${UserDefault})`
                          }`,
                        }}>
                        <div className='edit-pen mt-10'>
                          <i className='bi bi-plus-square-dotted' onClick={handleClick}></i>
                        </div>
                      </label>
                    </div>
                  </div>
          <div>
            <label className='form-label'>
             First Name
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.Fname) && Boolean(formik.errors?.Fname) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter First Name'
              name='Fname'
              value={formik.values.Fname}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.Fname && formik.errors.Fname} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
             Last Name
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.Lname) && Boolean(formik.errors?.Lname) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter Last Name'
              name='Lname'
              value={formik.values.Lname}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.Lname && formik.errors.Lname} marginBottom={-5} />
          </div>
          <div >
            <label className='form-label'>
              User Role
            </label>
            <select
            className={Boolean(formik.touched?.role) && Boolean(formik.errors?.role) ? 'form-select border-danger' : 'form-select '}
              name='role'
              value={formik.values.role}
              onChange={handleChange}>
              <option hidden>User Role</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>   
            </select>
            <InputErrorMessage error={formik.touched.role && formik.errors.role} marginBottom={-5} />
          </div>
          <div>
            <label className='form-label'>
             Email
            </label>
            <input
              type='text'
              className={Boolean(formik.touched?.email) && Boolean(formik.errors?.email) ? 'form-control border-danger' : 'form-control '}
              placeholder='Enter Email'
              name='email'
              value={formik.values.email}
              onChange={handleChange}
            />
            <InputErrorMessage error={formik.touched.email && formik.errors.email} marginBottom={-5} />
          </div>
          <div className='brief-button-container'>
            <button type='button' className='button-close' style={{ width: '100%' }} onClick={closeModal}>
              Cancel
            </button>
            <button type='submit' className='button-primary' disabled={updating} style={{ width: '100%' }}>
              {loader ? (
                <div className='text-xs text-center'>
                  <EditProfileLoader />
                </div>
              ) : (
                'Create User'
              )}
            </button>
          </div>
        </div>
      </form>

    </>
  );
}
