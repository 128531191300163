import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import './tableList.css';
import Moment from 'react-moment';

function TableList({hit, columnWidth, hqUsers, showStatusOptions, showPropDueDateOptions, showBriefOwnerOptions}) {

  const joinName = (owner) => {
    let fullName = `${owner[0].firstName} ${owner[0].lastName}`;
    return fullName;
  };
  const getUserAvatar = (userId) => {
    const avatarUrl = hqUsers?.hits?.find(hqUser => {
      return hqUser?.document?._id === userId;
    });
    return avatarUrl?.document?.profilePic;
  };
  function getProjectStatus(projectExpectedStartDate, projectExpectedEndDate) {
    const currentDate = new Date();
    const startDate = new Date(projectExpectedStartDate);
    const endDate = new Date(projectExpectedEndDate);

    // Extracting only the year, month, and day components from the dates
    const currentDateString = currentDate.toISOString().slice(0, 10);
    const startDateString = startDate.toISOString().slice(0, 10);
    const endDateString = endDate.toISOString().slice(0, 10);

    if (currentDateString === startDateString || (currentDate >= startDate && currentDate <= endDate)) {
        return "Active";
    } else if (currentDate < startDate) {
        return "Pending";
    } else {
        return "Completed";
    }
}

// Example usage:
const projectExpectedStartDate = "2024-02-09T00:00:00.000Z";
const projectExpectedEndDate = "2024-02-09T00:00:00.000Z";

const projectStatus = getProjectStatus(projectExpectedStartDate, projectExpectedEndDate);
console.log("Project status:", projectStatus);

  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item' >
          
          <div style={{width: columnWidth}} className='table-list-font-light'>
            {hit.refBriefId ? <Link to={`/campaigns/campaigns-view/${hit._id}`}>{hit?.name} </Link> : <Link to={`/briefs/edit-brief/${hit._id}`}>{hit?.name} </Link>}
          </div>
          <div style={{width: columnWidth}} className='table-list-font-light'>{hit.companyName}</div>
          {/* <div style={{width: columnWidth}} className='table-list-font-bold'>{hit.companyCurrency} {hit.feeAllocation[0].totalCost}</div> */}
          {showBriefOwnerOptions && <div style={{width: columnWidth, display: 'flex', alignItems: 'center'}} className='table-list-font-bold'><Avatar name={joinName(hit.owner)} url={getUserAvatar(hit.owner[0]?.userId)}/><span style={{marginLeft: '6px'}}>{joinName(hit.owner)}</span></div>}
          {/* {showStatusOptions && <div style={{width: columnWidth}}><span className='green-badge'>{getProjectStatus(hit?.projectExpectedStartDate,hit?.projectExpectedEndDate)}</span></div>} */}
          {showStatusOptions && <div style={{width: columnWidth}}><span className='green-badge'>{hit.status}</span></div>}
          <div style={{width: columnWidth}} className='table-list-font-light'><Moment format='DD/MM/YYYY' date={hit?.projectExpectedStartDate || hit?.createdAt } /></div>
          {showPropDueDateOptions && <div style={{width: columnWidth}} className='table-list-font-light'><Moment format='DD/MM/YYYY' date={hit?.projectExpectedEndDate || hit?.briefProposalDueDate} /></div>}
        </div>
      </div>
    </div>
  );
}

export default TableList;