import React, { createContext, useContext, useState } from "react";
import AdminLayout from "../../components/admin-layout";
import InviteSharePopup from "./InviteMasherPopup";
import GetReferralPopup from "../../components/Popups/GetReferralPopup";
import PageHeader from "../../components/pageElements/pageHeader/PageHeader";
import AddButton from "../../components/common/buttons/AddButton";
import plusIconCircle from "../../Icons/plusIconCircle.svg";
import { AppContext } from "../../appContext";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import TypesenseMasherListComponent from "../../components/TypesenseList/TypesenseMasherListComponent";
import SimpleButton from "../../components/common/buttons/SimpleButton/SimpleButton";
import ProfilePreview from "../MasherProfileView";
import SimpleModal from "../../components/Modals/SimpleModal";
import EditBrief from "../Briefs/EditBrief";
import Createinfluencer from "../influencer/Creatinfluencer";
import { Download } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../utils/baseurl";
import InflencerPreview from "../InfluencerProfilePreview";
export const MasherContext = createContext();

export default function Masher() {
  const [startBrief, setStartBrief] = useState(false);

  const { loggedInUser } = useContext(AppContext);
  const userId = loggedInUser?.data[0]?._id;

  // const typesenseInitiate = () => {
  //   try {
  //     const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  //       server: {
  //         apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  //         nodes: [
  //           {
  //             host: process.env.REACT_APP_HOST_NAME_NEW,
  //             protocol: "https",
  //           },
  //         ],
  //       },
  //       additionalSearchParameters: {
  //         collection: "influencer",
  //         q: `*`,
  //       },
  //     });
  //     return typesenseInstantsearchAdapter.searchClient;
  //   } catch (e) {
  //     return "failed";
  //   }
  // };
  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'influencer',
          sort_by: 'createdAt:asc',
          query_by: 'Name, createdAt,HandleID',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const [currentid, setcurrentid] = useState("");
  const searchClient = typesenseInitiate();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const startBriefDetails = () => {
    setStartBrief(!startBrief);
  };
  return (
    <AdminLayout>
      <MasherContext.Provider value={{}}>
        <PageHeader
          title="Influencers"
          buttonsArray={[
           
            <button
              className="simple-button primary"
              role="link"
              onClick={startBriefDetails}
            >
              <img className="simple-button-icon" src={plusIconCircle} />
              Create Influencer
            </button>,
          ]}
        />
        <div>
          <article>
            <main>
              <TypesenseMasherListComponent
                searchClient={searchClient}
                currentid={currentid}
                setcurrentid={setcurrentid}
                typesenseInitiate={typesenseInitiate}
              />
            </main>
          </article>
        </div>

        {/* Invite Modal */}
        {/* <ProfilePreview id={currentid} />
        <InviteSharePopup userId={userId} />
        <GetReferralPopup /> */}
         <InflencerPreview data={currentid} />
      </MasherContext.Provider>
      {startBrief && (
        <SimpleModal
          title="Create Influencer"
          // description="All fields are mandatory"
          closeModal={startBriefDetails}
        >
         <Createinfluencer  closeModal={startBriefDetails} userId={userId} />
          
        </SimpleModal>
      )}
      
    </AdminLayout>
  );
}
