import { GETINSTAGRAM,fileUpload, GET, PATCH, POST, NOTOKENPOST,fileUploadwithprogress,DeleteApi } from './api-interface';

// Login / User APIs
export const GEtinstagramapi = (id) => GETINSTAGRAM(`https://pcstaging.in/wlt/insights.php?username=${id}`);
export const loginApi = (payload) => NOTOKENPOST('/api/v1/common/auth/sendOtp', payload);
export const verifyOtp = (payload) => NOTOKENPOST('/api/v1/common/auth/verifyOtp', payload);
export const verifyToken = (payload) => NOTOKENPOST('/api/v1/common/auth/verifyToken', payload);
export const getUserApi = () => GET('/api/v1/user/byAuth');
export const updateUserApi = (payload) => PATCH('/api/v1/user/update', payload);
export const updateUserByIdApi = (payload) => PATCH('/api/v1/user/updateById', payload);
export const getRecentActivityApi = (payload) => GET('/api/v1/common/recentActivity/admin', payload);

// Brief APIs

// export const getBriefByIdApi = (id) => GET(`/api/v1/admin/brief/byId?id=${id}`);
// export const getProjectByIdApi = (id) => GET(`/api/v1/admin/project/byId?id=${id}`);
//update lastlogin ID...
export const UpdateLastLoginApi = (payload) => {
  return PATCH('/api/v1/user/updateLastLogin', payload);
};

export const downloadPDF = (id) => GET(`/api/v1/common/pdf/brief?id=${id}`);

export const getTimeZoneLocationApi = (search = '', lat = '', long = '' ) => GET(`/api/v1/user/masherProfile/timeZones?country=${search}&latitude=${lat}&longitude=${long}`);
// export const getTimeZonesApi = () => GET(`/json/timezone/timezone.json`);
export const createBriefApi = (payload) => POST('/api/v1/admin/brief/create', payload);
export const referMasherApi = (payload) => POST('/api/v1/common/refer/referAdmin', payload);
export const duplicateBriefApi = (payload) => POST('/api/v1/admin/brief/duplicate', payload);
export const duplicateBriefApiV2 = (payload) => POST('/api/v2/admin/brief/duplicate', payload);
export const editBriefApi = (payload) => PATCH('/api/v1/admin/brief/update', payload);
export const getBriefByIdApi = (id) => GET(`/api/v1/admin/brief/byId?id=${id}`);

//company..........
export const createCompanyApi = (payload) => POST('/api/v1/common/company/companycreate', payload);
//user..........
export const createUserApi = (payload) => POST('/api/v1/common/auth/adminRegister', payload);
export const updateUsersApi = (payload) => POST('/api/v1/common/auth/updateuser', payload);
export const updateUsersActiveDeactiveApi = (payload) => POST('/api/v1/common/auth/updateuserstatus', payload);
//influencer.........
export const createInfluencerApi = (payload) => POST('/api/v1/common/influencer/createinfluencer', payload);
export const EditInfluencerApi = (payload) => POST('/api/v1/common/influencer/Editinfluencer', payload);

// v2 API endpoints
export const createBriefApiV2 = (payload) => POST('/api/v2/admin/brief/create', payload);
export const getBriefByIdApiV2 = (id) => GET(`/api/v2/admin/brief/byId?id=${id}`);
export const editBriefApiV2 = (payload) => PATCH('/api/v2/admin/brief/update', payload);
export const addDeliverableApi = (payload) => PATCH('/api/v2/admin/brief/addDeliverable', payload);
export const addRoleApi = (payload) => PATCH('/api/v2/admin/brief/addRole', payload);
export const editDeliverableNameApi = (payload) => PATCH('/api/v2/admin/brief/editDeliverableName', payload);
export const editBriefTextApi = (payload) => PATCH('/api/v2/admin/brief/updatebrieftxt', payload);
export const assignMasherToRoleApi = (payload) => PATCH('/api/v2/admin/brief/assignMasherToRole', payload);
export const editBriefStatusApi = (payload) => PATCH('/api/v2/admin/brief/editBriefStatus', payload);
export const removeRoleFromDeliverableApi = (payload) => PATCH('/api/v2/admin/brief/removeRoleFromDeliverable', payload);
export const removeDeliverableApi = (payload) => PATCH('/api/v2/admin/brief/removeDeliverable', payload);
export const removeMasherFromRoleApi = (payload) => PATCH('/api/v2/admin/brief/removeMasherFromRole', payload);
export const saveFeeAllocationsApi = (payload) => PATCH('/api/v2/admin/brief/saveFeeAllocations', payload);
export const allRolesApi = (limit = 0, page = 1) => GET(`/api/v2/admin/profile/getAll?limit=${limit}&page=${page}&type=roles`);
export const sendBriefApiV2 = (payload) => POST('/api/v2/admin/brief/send', payload);
export const saveDateRangeApiV2 = (payload) => PATCH('/api/v2/admin/brief/updateDateRange', payload);
export const saveProjectDateRangeApi = (payload) => PATCH('/api/v2/admin/project/updateDateRange', payload);
export const addContributorsV2 = (payload) => POST('/api/v2/admin/brief/addContributors', payload);
export const addSubcultureV2 = (payload) => PATCH('/api/v2/admin/brief/addSubculture', payload);
export const removeSubcultureV2 = (payload) => PATCH('/api/v2/admin/brief/removeSubculture', payload);
export const addAudienceV2 = (payload) => PATCH('/api/v2/admin/brief/addAudience', payload);
export const removeAudienceV2 = (payload) => PATCH('/api/v2/admin/brief/removeAudience', payload);
export const briefToProjectV2 = (payload) => POST('/api/v2/admin/brief/briefToProject', payload);
export const updateMasherPreferenceApiV2 = (payload) => PATCH('/api/v2/admin/brief/updateMasherPreference', payload);
export const checkBriefInvitesSentApiV2 = (id = 0) => GET(`/api/v2/admin/brief/checkBriefInvitesSent?id=${id}`);

export const getAllArchivedApi = (
  briefName = '',
  startDate = '',
  dueDate = '',
  limit = '',
  page = '1',
  isProject = '',
  brandName = '',
  createdBy = '',
  minBudget = '',
  maxBudget = '',
  fieldName = '',
  orderBy = '',
) => GET(`/api/v1/admin/brief/archived?briefName=${briefName}
&startDate=${startDate}&dueDate=${dueDate}&limit=${limit}&page=${page}&isProject=${isProject}&brandName=${brandName}&createdBy=${createdBy}&minBudget=${minBudget}&maxBudget=${maxBudget}&fieldName=${fieldName}&orderBy=${orderBy}`);
export const getAllBriefApiForPagination = (page = '') => GET(`/api/v1/admin/brief/allBriefs?page=${page}`);
export const getLatestBriefApi = (id) => GET('/api/v1/admin/brief/latestBriefs');
export const getAllBriefApi = (
  briefName = '',
  startDate = '',
  dueDate = '',
  limit = '',
  page = '1',
  isProject = '',
  brandName = '',
  createdBy = '',
  minBudget = '',
  maxBudget = '',
  fieldName = '',
  orderBy = '',
) =>
  GET(`/api/v1/admin/brief/allBriefs?briefName=${briefName}
&startDate=${startDate}
&dueDate=${dueDate}
&limit=${limit}
&page=${page}
&isProject=${isProject}
&brandName=${brandName}
&createdBy=${createdBy}
&minBudget=${minBudget}
&maxBudget=${maxBudget}
&fieldName=${fieldName}
&orderBy=${orderBy}`);

export const getAllBriefApiV2 = ({
  briefName = '',
  startDate = '',
  dueDate = '',
  limit = '',
  page = '1',
  isProject = '',
  brandName = '',
  createdBy = '',
  minBudget = '',
  maxBudget = '',
  fieldName = '',
  orderBy = '',
}) =>
  GET(`/api/v2/admin/brief/getAll?briefName=${briefName}
&startDate=${startDate}
&dueDate=${dueDate}
&limit=${limit}
&page=${page}
&isProject=${isProject}
&brandName=${brandName}
&createdBy=${createdBy}
&minBudget=${minBudget}
&maxBudget=${maxBudget}
&fieldName=${fieldName}
&orderBy=${orderBy}`);
// Project APIs

export const getAllprojectApi = (
  briefName = '',
  startDate = '',
  dueDate = '',
  limit = '',
  page = '1',
  isProject = '',
  brandName = '',
  createdBy = '',
  minBudget = '',
  maxBudget = '',
  fieldName = '',
  orderBy = '',
)  =>
  GET(`/api/v1/admin/project/allprojects?briefName=${briefName}&startDate=${startDate}&dueDate=${dueDate}&limit=${limit}&page=${page}&isProject=${isProject}&brandName=${brandName}&createdBy=${createdBy}&minBudget=${minBudget}&maxBudget=${maxBudget}&fieldName=${fieldName}&orderBy=${orderBy}`);
export const convertToProjectApi = (payload) => POST('/api/v1/admin/project/create', payload);
export const editProjectApi = (payload) => PATCH('/api/v1/admin/project/update', payload);
export const getProjectByIdApi = (id) => GET(`/api/v1/admin/project/byId?id=${id}`, id);
export const getProjectByIdApiV2 = (id) => GET(`/api/v2/admin/project/byId?id=${id}`, id);
export const fetchRegionApi = () => GET('/json/region/region.json');
export const fetchClientStatusApi = () => GET('/json/clientStatus/status.json');
export const fetchIndustryApi = () => GET('/api/v1/helper/industry-list');
export const fetchCountrycodeApi = () => GET('/json/countryCode/countryCode.json');
export const fetchEngagementTypeApi = () => GET('/json/engagementType/engagementType.json');
export const fetchCategoryApi = () => GET('/json/categories/categories.json');
export const fetchClientApi = () => GET('/json/clientType/clientType.json');
export const fetchClientApiV2 = () => GET('/api/v2/admin/companies/allCompanies');
export const uploadProfileImgApi = (payload) => fileUpload('/api/v1/common/uploads/file', payload);
//fikle upload API for brief...
export const fileUploadApi = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/briefattach', payload,onUploadProgress,file);
};
//fikle upload API for projects...
export const ProjectfileUploadApi = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/projectattach', payload,onUploadProgress,file);
};
export const createBriefAttachApi = (payload) => POST('/api/v2/admin/brief/attachment/add', payload);
export const createProjectAttachApi = (payload) => POST('/api/v2/admin/project/attachment/add', payload);

//attachment Delete 

export const DeleteBriefAttachApi = (briefID,fileID) => DeleteApi(`/api/v2/admin/brief/attachment/remove?briefId=${briefID}&attachId=${fileID}`);
export const DeleteProjectAttachApi = (projectID,fileID) => DeleteApi(`/api/v2/admin/project/attachment/remove?projectId=${projectID}&attachId=${fileID}`);

export const getDashboardCountApi = () => GET('/api/v1/admin/dashboard/counts');
//other skills json...
export const getOtherSkillsApi = () => GET('/json/skills/skills.json');
 


// Masher APIs

export const getAllMashersApi = (p) => {
  return GET(
    `/api/v1/user/masherProfile/allMasherProfiles?search=${p.search}&page=${p.page}&limit=${p.limit}&status=${p.status}&skills=${p.skills}&maxExperience=${p.maxExperience}&minExperience=${p.minExperience}&location=${p.location}&industryType=${p.industryType}&clientsWorkedFor=${p.clientsWorkedFor}&startDate=${p.startDate}&dueDate=${p.dueDate}&fieldName=${p.fieldName}&orderBy=${p.orderBy}`
  );
};

export const getAllInternalMashers = (p) => {
  return GET(
    '/api/v1/user/masherProfile/allInternalMashers'
  );
};


export const getMasherShareApi = (
  search = '',
  skills = '',
  minExperience = '',
  maxExperience = '',
  location = '',
  page = '1',
  limit = '',
  status = 'approved',
  industryType='',
  clientsWorkedFor='',
) => {
  return GET(
    `/api/v1/user/masherProfile/allMasherProfiles?search=${search.trim()}&page=${page}&limit=${limit}&status=${status}&skills=${skills}&maxExperience=${maxExperience}&minExperience=${minExperience}&location=${location}&industryType=${industryType}&clientsWorkedFor=${clientsWorkedFor}`
  );
};

export const getAllUsers = (
  search = '',
  startDate = '',
  dueDate = '',
  limit = 50,
  page = 1,
) => {
  return GET(
    `/api/v1/user/allUsers?search=${search.trim()}&startDate=${startDate}&dueDate=${dueDate}&limit=${limit}&page=${page}`
  );
};

export const getPendingMashersApi = (search = '', page = 1, limit = 5, status = 'pending') => {
  return GET(`/api/v1/user/masherProfile/allMasherProfiles?search=${search}&page=${page}&limit=${limit}&status=${status}`);
};
export const getAllClientsApi = (
  search = '', 
  limit, 
  page = 1,
  industry = '',
  type='',
  region='',
  fieldName='',
  orderBy='',
) => {
  return GET(`/api/v1/admin/client/allClients?search=${search}&limit=${limit}&page=${page}&industry=${industry}&type=${type}&region=${region}&fieldName=${fieldName}&orderBy=${orderBy}`);
};

export const getAllArchiveApi = (
  search = '', 
  limit, 
  page = 1,
  industry = '',
  type='',
  region='',
  fieldName='',
  orderBy='',
) => {
  return GET(`/api/v1/admin/client/allArchiveClients?search=${search}&limit=${limit}&page=${page}&industry=${industry}&type=${type}&region=${region}&fieldName=${fieldName}&orderBy=${orderBy}`);
};


export const getClientByIdApi = (id) => {
  return GET(`/api/v1/admin/client/byId?id=${id}`);
};

export const getMasherByIdApi = (id) => {
  return GET(`/api/v1/user/masherProfile/byId?id=${id}`);
};
export const getMasherReferenceApi = (id) => {
  return GET(`/api/v1/common/refer/byId?id=${id}`);
};

export const addMasherNotesApi = (payload) => {
  return POST('/api/v1/admin/notes/create', payload);
};

export const getMasherNotesApi = (id) => {
  return GET(`/api/v1/admin/notes/byId?id=${id}`);
};

export const updateMasherProfileApi = (payload) => {
  return PATCH('/api/v1/user/masherProfile/update', payload);
};

export const inviteMasherApi = (payload) => {
  return POST('/api/v2/common/briefInvitation/create', payload);
};

export const weavyTokenApi = (payload) => {
  return POST('/api/v2/common/weavy/getToken', payload);
};

export const weavyAddProjectApi = (payload) => {
  return POST('/api/v2/common/weavy/addProject', payload);
};

export const extendTimeForBriefApi = (id) => {
  return POST(`/api/v1/common/briefInvitation/update?id=${id.id}`, id);
};

export const chatMasherApi = (id) => {
  return GET(`/api/v1/common/briefInvitation/byId?id=${id}`);
};

export const createClient = (payload) => {
  return POST('/api/v1/admin/client/create', payload);
};

export const editClientApi = (payload) => {
  return PATCH('/api/v1/admin/client/update', payload);
};

export const getLocationInputApi = (search) => {
  return GET(`/api/v1/common/google/google?input=${search}`);
};
 
export const isValidWebsiteLink = (search) => {
  return GET(`/api/v1/helper/checkUrl?url=${search}`);
};

export const syncLiveblocks = (payload) => {
  return PATCH('/api/v1/common/collaborative/sync', payload);
};