import React, { useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../../../../components/errorMessages';
import { editBriefTextApi } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';

export default function EditLivelock({ briefId, briefDetails, closeModal }) {
  const [updating, setUpdating] = useState(false);
  const { details } = briefDetails;
  
  const liveblockvalidationSchema = yup.object({
    briefcomeout: yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      briefcomeout: details?.[0]?.briefcomeout || '',
    },
    validationSchema: liveblockvalidationSchema,
    onSubmit: async (values) => {
      try {
        setUpdating(true);
        const res = await editBriefTextApi({
          id: briefId,
          briefcomeout:values.briefcomeout
        });
        setUpdating(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });          
          closeModal();
        } else {

        }
      } catch(e) {
        closeModal();
      }
    },
  });
  
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (    
    <form onSubmit={formik.handleSubmit}>
      <div className='row'>
        <div>
          <label className='form-label'>
             How did this brief come about?
          </label>
           <textarea  placeholder='Enter...'
            name='briefcomeout'
            cols={30} rows={5} 
            onChange={handleChange}
            value={formik.values.briefcomeout}
            className={Boolean(formik.touched?.briefcomeout) && Boolean(formik.errors?.briefcomeout) ? 'w-full p-4 rounded-lg border border-gray-300 resize-none border-danger' : 'w-full p-4 rounded-lg border border-gray-300 resize-none '}
            ></textarea>
          <InputErrorMessage error={formik.touched.briefcomeout && formik.errors.briefcomeout} marginBottom={-5} />
        </div>
        <div className='brief-button-container'>
          <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
            {updating ? (
              <div className='text-xs text-center'>
                <EditProfileLoader />
              </div>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
