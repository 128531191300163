import React, { useState } from 'react';
import Avatar from '../../../components/Avatar/Avatar';
import ProfilePreview from '../../MasherProfileView';
import plusIconBlack from '../../../Icons/plusIconBlack.svg';
import AddPost from './AddPost';
import SimpleModal from '../../../components/Modals/SimpleModal';
function RoleCardProject({deliverable}) {
  const[currentid,setcurrentid]=useState('');
  const[openmodal,setopenmodal]=useState(false);
  const updatemodal=()=>{
    setopenmodal(!openmodal)
  }
  return (
    <div>
      {deliverable.role.map((role, i) => {
        return (
          <div className='role-card'>
            {
              role?.Masher.map((masher, index) => {
                return (
                  
                  <div key={index} className='project-role-card-row'> 

                    <div className='project-role-card-masher-details-left'>    
                      <div style={{width: '320px'}}>
                        <h5 style={{marginBottom: '5px'}}>{ deliverable.deliverableName }</h5>
                        <p style={{textWrap: 'pretty'}}>{deliverable.role ? role?.roleName : ''}</p>
                      </div>
                      <div style={{width: '420px'}}>
                      <a 
                        data-bs-toggle="modal"
                        data-bs-target="#profilePreview"
                        id="profilePreview_open"
                        className='flex items-center'
                        onClick={()=>{
                          setcurrentid(masher);
                        }}
                      >
                        <Avatar id={masher.Id} name={masher.Name} url={masher.Photo} borderColor='white'/>
                        <p style={{marginLeft: '10px'}}>{masher.Name}</p>
                      </a>
                      </div>
                      {/* <div style={{width: '220px'}}>
                        <h5 style={{marginBottom: '5px'}} className='d-flex items-center gap-2 cursor-pointer'>2 posts 
                        <img src={plusIconBlack} onClick={()=>{updatemodal()}}  /> </h5>     
                      </div> */}
                    </div>


                  </div>
                  
                );
              }) 
            }
          </div>
        );
      }) }
      <ProfilePreview data={currentid}/>
      {
            openmodal && 
              <SimpleModal title='Add Post' description='' closeModal={updatemodal} >
                <AddPost  closeModal={updatemodal} />
              </SimpleModal>
          }
    </div>
      
  );
}

export default RoleCardProject;